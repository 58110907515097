import { HttpService } from './http.service';
import { ErrorWrapper } from './utils';
import { GetStickersResponse } from "@/types/stickers.types";


export default class StickersService {
  static get stickers(): string {
    return 'stickers';
  }

  static async getStickers(unicode: string): Promise<GetStickersResponse> {
    try {
      const response = await HttpService.get(`/${this.stickers}?unicode=${unicode}`);

      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;

      throw new ErrorWrapper(error, message);
    }
  }

  static async getStickerPacks(offset: number = 0, limit: number = 20): Promise<GetStickersResponse> {
    try {
      const response = await HttpService.get(`/${this.stickers}?limit=${limit}&offset=${offset}`);

      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;

      throw new ErrorWrapper(error, message);
    }
  }
}
