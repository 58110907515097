import { Container, Link } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { ReactComponent as QuestionIcon } from '@/assets/icons/question.svg';
import { ReactComponent as ExpandMoreIcon } from '@/assets/icons/expand-more-items.svg';
import { useState } from 'react';
import useIsStaffUserRole from '@/hooks/useIsStaffUserRole';
import { useAppSelector } from '@/app/hooks';
import { ISettings } from '@/types/user-profile.types';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 'calc(100% - 56px)',
    padding: '28px',
    marginTop: '-12px',
    zIndex: '90',
    background: '#FFFFFF',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  headTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '26px',
    lineHeight: '26px',
    paddingBottom: '33px',
    width: '100%',
    textAlign: 'center',
  },
  paragraphFirst: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    paddingBottom: '10px',
  },
  paragraph: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    paddingBottom: '10px',
    paddingLeft: '40px',
  },
  headline: {
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '44px',
    marginBlockStart: 0,
    marginBlockEnd: 0,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: '#503EB6',
    paddingInlineStart: '20px',
    '& > ::marker': {
      color: '#EA4160',
    },
  },
  breakline: {
    background: '#E7E6E6',
    height: '1px',
    width: '100%',
    marginBottom: '10px',
  },
  questionTitle: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '18px',

    color: '#000000',
    paddingBottom: '8px',
    display: 'flex',
    '& svg': {
      paddingRight: '10px',
    },
  },
  questionBody: {
    fontSize: '14px',
    lineHeight: '20px',

    color: '#808080',
    paddingBottom: '16px',
    paddingLeft: '28px',
  },
  styledOl: {
    paddingInlineStart: '14px',
    '& > ::marker': {
      color: '#503EB6',
    },
  },
  link: {
    display: 'inline',
    color: '#EA4160',
  },
}));

const FAQ = () => {
  const classes = useStyles();
  const {
    supportEmail,
    siteUrl,
    creditsMessageOpen,
    creditsMessageSend,
    creditsMessagePhotoOpen,
    creditsMessageVideoSend,
    creditsMessageVideoOpen,
    creditsChatPhotoSend,
    creditsChatPhotoOpen,
    creditsChatTextMin,
    creditsChatVideoMin,
    creditsChatVideoSend,
    creditsChatVideoOpen,
    creditsDialogTextSend,
    creditsDialogPhotoSend,
    creditsDialogPhotoOpen,
    creditsDialogVideoSend,
    creditsDialogVideoOpen,
    creditsProfilesBlacklistAdd,
    creditsStickerSend,
  } = useAppSelector<ISettings>((state) => state.users.settings);
  const isStaff = useIsStaffUserRole();
  const [expanded, setExpanded] = useState<string | false>('panel1');
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (isStaff) {
    return (
      <Container maxWidth={'xl'}>
        <div className={classes.container}>
          <div className={classes.headTitle}>ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</div>
          <div className={classes.paragraphFirst}>
            На этой странице отображаются наиболее часто задаваемые вопросы от наших пользователей. Все ответы,
            представленные на этой странице, носят рекомендательный характер и не имеют юридической силы.
          </div>
          <Accordion
            style={{ boxShadow: 'none', borderBottom: '1px solid #E7E6E6' }}
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
              <Typography>
                <ol start={1} className={classes.headline}>
                  <li>Регистрация и авторизация</li>
                </ol>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Я хочу прочитать Соглашение об обслуживании и другие документы, где я могу их найти?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>Вы можете найти любые документы по адресу:</div>
                  <ul className={classes.styledOl}>
                    <li>
                      Соглашение об условиях использования сайта, по ссылке:{' '}
                      <Link href={'/terms-of-use'} underline='none'>
                        <div className={classes.link}> {siteUrl}</div>
                      </Link>
                    </li>
                    <li>
                      Политика конфиденциальности сайта, по ссылке{' '}
                      <Link href={'/privacy-policy'} underline='none'>
                        <div className={classes.link}> {siteUrl}</div>
                      </Link>
                    </li>
                    <li>
                      Политика против мошенничества, по ссылке:{' '}
                      <Link href={'/anti-scam-policy'} underline='none'>
                        <div className={classes.link}> {siteUrl}</div>
                      </Link>
                    </li>
                    <li>
                      Политика Cookie, по ссылке:{' '}
                      <Link href={'/cookie-policy'} underline='none'>
                        <div className={classes.link}> {siteUrl}</div>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Зачем мне регистрироваться на сервисе?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Администрация нашего сайта должна идентифицировать Вас как отдельного пользователя, чтобы Вы могли
                    выполнять действия с нашим сервисом и сохранять Ваши результаты. Например: если Вы не
                    зарегистрированы на нашем сайте, Вы не имеете возможности создать список Ваших фаворитов, и мы не
                    можем сделать это вместо Вас.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Что я могу сделать без регистрации?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    На нашем сайте доступна вся необходимая информация для Вашего ознакомления. Вы можете ознакомиться с
                    информацией на главной странице сервиса, искать собеседников для общения, просматривать список
                    пользователей, их анкеты и альбомы с фотографиями. Кроме того, Вы можете отправлять Ваши запросы и
                    предложения как администратору Вашего агентства, так и в службу поддержки сайта.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Регистрация на сайте платная?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>Регистрация на сервисе абсолютно бесплатна.</div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Что делать, если я забыл(а) пароль от своей учетной записи?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Для этого воспользуйтесь формой восстановления пароля, которую легко найти в блоке авторизации.
                    Кроме того, Вы можете обратится на прямую к Вашему администратору, адресовать нам этот вопрос по
                    электронной почте или воспользоваться формой обратной связи.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>
                    Что делать, если я хочу изменить адрес электронной почты, на который зарегистрирован мой аккаунт?
                  </div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Для этих целей обратитесь к Вашему администратору, также Вы можете написать письмо в службу
                    поддержки, используя форму обратной связи. В своем письме введите данные, подтверждающие, что Вы
                    являетесь владельцем учетной записи на нашем сервисе. Мы изучим эти данные при получении письма, в
                    случае если предоставленная информация верна, мы свяжемся с Вами.
                  </div>
                  <div>
                    Важно! После регистрации адрес электронной почты, с которым Вы связали свою учетную запись, изменить
                    нельзя.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>У меня украли аккаунт, что мне делать</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    В случае кражи Вашего аккаунта, пожалуйста, обратитесь к администратору Вашего агентства. Одним из
                    способов заявить о данной проблеме является написание письма в службу поддержки нашего сайта на
                    странице Contact Us (для гостей), используя форму обратной связи. Также Вы можете отправить письмо
                    по электронной почте на адрес{' '}
                    <a href='mailto:{supportEmail}'>
                      <div className={classes.link}>{supportEmail}</div>
                    </a>
                    . Как только мы получим Ваше письмо, мы проверим всю информацию, доверенную Вами, и предоставим Вам
                    подробные инструкции о Ваших дальнейших действиях.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Как я могу удалить свой аккаунт?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Обратитесь к администратору своего агентства или по электронной почте. Также Вы можете написать
                    письмо в службу поддержки, используя форму обратной связи. В теме письма укажите «Удаление
                    аккаунта», а также приложите видео-обращение с намерением удалить Вашу учетную запись и причину
                    удаления. Вы должны указать ID своей учетной записи и адрес электронной почты регистрации. Когда мы
                    получим электронное письмо, мы свяжемся с Вами, чтобы подтвердить удаление Вашей учетной записи.
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ boxShadow: 'none', borderBottom: '1px solid #E7E6E6' }}
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
              <Typography>
                <ol start={2} className={classes.headline}>
                  <li>Услуги сервиса</li>
                </ol>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Что делать после регистрации?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Чтобы Ваш профиль выглядел более привлекательным в глазах собеседников, Вам следует заполнить
                    информацию о себе и загрузить несколько фотографий (минимум 10 фотографий высокого качества из
                    Вашего портфолио в пристойном виде, в формате jpg или jpeg размером до 2 Mb и разрешением не менее
                    720х720 и не более 3000х3000 пикселей). После этого Вы можете искать профили собеседников или списки
                    профилей, рекомендованных к просмотру. Найдя интересующего Вас собеседника, сохраните его профиль
                    добавьте в контакты.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Какую информацию я могу разместить в своем профиле?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    При использовании нашего сервиса Вам нужно внести в свой аккаунт всю основную контактную информацию
                    о Вас, включая Ваше имя, фамилию и отчество, а так же персональный e-mail и данные о Вашей
                    внешности. Постарайтесь сделать свой аккаунт максимально привлекательным для противоположного пола.
                    Обращаем Ваше внимание, что все данные должны быть правдивые, поэтому не включайте ложную информацию
                    в свой профиль.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Нужно ли загружать мои фотографии?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Конечно, это действие является обязательным. Стоит добавить 10 ваших лучших фото в публичный альбом.
                    На нашем сайте разрешено загружать фотографии в формате JPEG и PNG, с разрешением не менее 720px x
                    720px и не более 3072px x 1620px, а также не более 2 Мб. Более того, когда Вы устанавливаете
                    красивую картинку в качестве своего аватара, Ваш профиль становится более привлекательным.
                  </div>
                  <div>
                    Администраторы сайта имеют возможность удалить фотографии из общедоступных альбомов, если они
                    содержат порнографический и неподобающий материал или непристойное обнажение.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Кто-нибудь еще видит мои приватные фотографии?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Ваши приватные фотографии могут видеть только те пользователи, которым Вы отправили эти фотографии в
                    письме и/или в чате.
                  </div>
                  <div>
                    Никто не может видеть Ваш приватный альбом, кроме Вас. Такой приватный альбом необходим для того,
                    чтобы Вы могли загружать фотографии, недоступные для всех пользователей. Вы можете отправить эти
                    фотографии с письмом или в чате собеседнику, которого выбрали.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Зачем мне Контакты?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Вы можете добавить собеседников в свой список Контактов, или сам собеседник может добавить Вас в
                    свой список Контактов. Мы предлагаем Вам добавить в этот список собеседников, с которыми Вы часто
                    общаетесь, или можете добавить тех пользователей, которые интересуют Вас больше других. Мы советуем
                    Вам не забыть позже написать любимому собеседнику или посетить его профиль.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Могу ли я видеть гостей моего профиля?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Да, конечно. Если Вы хотите увидеть гостей, Вы можете легко сделать это, используя соответствующий
                    раздел меню «Гости». Этот раздел фиксирует всех посетителей Вашего профиля.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Могу ли я отключить уведомления?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Конечно, это возможно! В «Настройках» Вы можете отключить визуальные или звуковые уведомления. Для
                    этого нажмите на свой аватар в правом верхнем углу и выберите «Настройки».
                  </div>
                  <div>Визуализацию системных уведомлений невозможно отключить.</div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Каковы особенности при использовании сервиса?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>Используя наш сервис, Вы можете:</div>
                  <ul>
                    <li>искать собеседников;</li>
                    <li>сохранять списки собеседников и Контакты;</li>
                    <li>отслеживать посетителей Вашего профиля;</li>
                    <li>писать письма собеседникам;</li>
                    <li>отправлять виртуальные подарки понравившимся собеседникам;</li>
                    <li>отслеживать самые интересные новости на сайте;</li>
                    <li>общаться в текстовом чате;</li>
                    <li>общаться в видео-чате;</li>
                  </ul>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Есть ли у собеседников "приватные" фотографии? Как я могу их просмотреть?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Конечно, у каждого пользователя на сайте есть альбом с его личными фотографиями. В случае, если Вы
                    хотите увидеть все его фотографии, Вы можете отправить ему запрос или письмо и спросить, может ли он
                    предоставить фотографии
                  </div>
                  <div>
                    Мы советуем Вам для начала познакомиться с собеседником, написать ему, попытаться узнать его лучше и
                    только после этого попросить приватные фото.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Что если я напишу письмо собеседнику, а он не ответит?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Администрация нашего сервиса не заявляет и не гарантирует, что пользователь ответит на Ваше письмо.
                    Сначала постарайтесь быть интересным этому собеседнику, посетите его профиль, изучите его и
                    пригласите пользователя в чат.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Могу ли я использовать чат, если у меня нет камеры или микрофона?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>Если у Вас нету камеры, Вы имеете возможность использовать только текстовый чат.</div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>В чем разница между текстовыми и видео?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    В текстовом чате Вы можете общаться с пользователями, используя только текст и смайлы. Видео чат
                    дает Вам возможность показать себя и просмотреть видео с камеры собеседника, а именно общаться лицом
                    к лицу.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Могу ли я получать фото от пользователей сервиса?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Да, сервис предоставляет такую возможность. Все фото пользователей публичных альбомов Вы можете
                    просмотреть в их альбомах, зайдя на их личные страницы. А приватные фото Вам могут выслать в письме
                    или в чате.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Могу ли я отправлять пользователям свои фото?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Конечно! Вы можете отправлять свои фото из приватного альбома тем пользователям, с которыми у Вас
                    есть активный чат или через личное письмо. Каждое фото можно отправлять конкретному пользователю
                    только 1 раз.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>
                    Как я могу пригласить пользователя в чат, не отвлекаясь от активных чатов в реальном времени?
                  </div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Вы легко можете пригласить собеседника в чат, из тех кто онлайн во время уже активного чата с
                    другими собеседниками, воспользовавшись функцией поиска в чате по онлйну в левом окне чата.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Есть ли история сообщений в чате?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Настройки чата позволяют записывать Вашу переписку во время текущей сессии окна браузера. В случае,
                    если Вы закроете окно браузера или сам браузер, история сообщений будет удалена. В то же время
                    каждый собеседник, с которым Вы общались, сохраняется в списке «Last chat».
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Если я закрою окно чата, он остановится?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    После закрытия браузера все активные чаты с Вашими собеседниками прекратятся, а история переписки
                    будет удалена.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Видео из видео-чата - это запись, не так ли?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Нет, видеоизображение передается в режиме реального времени с камеры собеседника Вам и наоборот.
                  </div>
                </div>

                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Могу ли я отправить подарок собеседнику?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Да, Вы можете отправить виртуальный подарок пользователям не более одного раза в месяц. Вы имеете
                    право отправлять виртуальные подарки всем пользователям. Вы можете отправить виртуальный подарок
                    любой тематики в количестве 1 штука, а также Вы можете отправить один дополнительный подарок ко дню
                    рождения пользователя или на тематический праздник, а именно, если в стране проживания пользователя
                    отмечают национальные праздники, такие как Новый Год, Рождество, Пасха или Хелловин. Вместе с
                    подарком Вы имеете полное право написать сопроводительное письмо с количеством символов не более
                    140.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Как виртуальный подарок, который я заказываю на сайте, попадает к собеседнику?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Как только Вы заказали виртуальный подарок для пользователя, Ваш заказ будет обработан. После
                    обработки Ваш виртуальный подарок попадает в раздел «Подарки» в его профайле.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Можно ли сделать виртуальный подарок, которого нет в списке на сервисе?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    К сожалению, это невозможно. Если Вы хотите подарить подарок, которого нет в списке, вы можете
                    отправить запрос на него через администратора Вашего агентства, используя форму обратной связи или
                    по электронной почте. Возможно, в будущем, мы добавим подарки в список, которые Вы предложили.
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ boxShadow: 'none', borderBottom: '1px solid #E7E6E6' }}
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
              <Typography>
                <ol start={3} className={classes.headline}>
                  <li>Общение и отношения между участниками на сайте</li>
                </ol>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Есть ли на сайте участники в возрасте до 18 лет?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    В случае, если Вы заподозрили, что на сайте есть пользователи менее 18 лет, Вы можете описать эту
                    проблему Вашему администратору в письменной форме, или воспользоваться электронной почтой и/или
                    формой обратной связи.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>На каких языках пользователи будут общаться со мной на данном сервисе?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Наш сервис обязует всех пользователей общаться на английском языке, уровень которого можно увидеть в
                    профайле каждого пользователя в отдельности.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Есть ли на вашем сервисе услуги перевода на английский язык в чате?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Да, наш сервис предоставляет возможность переводить Вашу переписку с собеседником в чате в онлайн
                    режиме с помощью встроенного переводчика. Во время общения в чате Вы сможете переводить Ваши
                    исходящие сообщения перед отправкой, а также входящие сообщения от пользователей-мужчин.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Как мне перевести входящие письма и ответить на них, если мой уровень английского низкий?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Не переживайте, если Вы владеете иностранным языком на не достаточном для понимания уровне. Вы
                    всегда можете обратиться к администрации Вашего агентства с просьбой о помощи в профессиональном
                    переводе. Только так наш сайт может гарантировать достоверность и правильность перевода каждого
                    предложения, что намного облегчит понимание и общение между Вами и Вашим собеседником.
                  </div>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Могу ли я встретиться с собеседником?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Да, конечно! Вы можете встретится с пользователем, которого Вы выбрали и общение с которым переросло
                    в нечто большее. Для этого необходимо сделать запрос для встречи собеседнику и уведомить об этом
                    администрацию сайта и Вашего агентства. Администрация нашего сервиса рекомендует организовать первую
                    встречу исключительно с Вашим фаворитом и только на территории страны Вашего проживания.
                  </div>
                  <div>
                    Вы должны помнить, что встречи возможны только по взаимному согласию сторон. Перед тем, как сделать
                    такой серьезный шаг, мы предлагаем Вам обсудить с выбранным человеком Ваше намерение встретиться с
                    ним, а также остальные детали его приезда.
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ boxShadow: 'none', borderBottom: '1px solid #E7E6E6' }}
            expanded={expanded === 'panel4'}
            onChange={handleChange('panel4')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
              <Typography>
                <ol start={4} className={classes.headline}>
                  <li>Технические вопросы</li>
                </ol>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Каковы системные требования для сайта?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>Необходимая скорость соединения до 50 Мбит / с и пинг 100 мс.</div>
                  <div>Для использования сайта желательно использовать один из браузеров:</div>
                  <ul>
                    <li>Internet Explorer 10 и выше</li>
                    <li>Опера 15.0 и выше</li>
                    <li>Google Chrome 19.0 и выше</li>
                    <li>Mozilla Firefox 13.0 и выше</li>
                    <li>Safari 6/0 и выше</li>
                  </ul>
                </div>
                <div className={classes.questionTitle}>
                  <QuestionIcon />
                  <div>Что делать, если я обнаружил ошибку на сайте?</div>
                </div>
                <div className={classes.questionBody}>
                  <div>
                    Вы должны сообщить об этой проблеме администратору Вашего агентства или в службу поддержки нашего
                    сайта или по электронной почте. Как только мы получим от Вас письмо, мы проверим всю предоставленную
                    информацию.
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </Container>
    );
  }

  // Default FAQ
  return (
    <Container maxWidth={'xl'}>
      <div className={classes.container}>
        <div className={classes.headTitle}>FAQ</div>
        <div className={classes.paragraphFirst}>
          This page displays the most frequently asked questions from our users. The answers presented on this page are
          indicative and recommendatory and do not constitute any legal force.
        </div>
        <Accordion
          style={{ boxShadow: 'none', borderBottom: '1px solid #E7E6E6' }}
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
            <Typography>
              <ol start={1} className={classes.headline}>
                <li>Registration & Authorization</li>
              </ol>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>I want to read the Service Agreement and other documents, where can I find them?:</div>
              </div>
              <div className={classes.questionBody}>
                <div>You can find any documents at:</div>
                <ul className={classes.styledOl}>
                  <li>
                    Site Terms of Use Agreement, at
                    <Link href={'/terms-of-use'} underline='none'>
                      <div className={classes.link}> link</div>
                    </Link>
                  </li>
                  <li>
                    Site Refund Policy, at{' '}
                    <Link href={'/refund-policy'} underline='none'>
                      <div className={classes.link}> link</div>
                    </Link>
                  </li>
                  <li>
                    Site Privacy Policy, at{' '}
                    <Link href={'/privacy-policy'} underline='none'>
                      <div className={classes.link}> link</div>
                    </Link>
                  </li>
                  <li>
                    Site Complaint Policy, at{' '}
                    <Link href={'/complaint-policy'} underline='none'>
                      <div className={classes.link}> link</div>
                    </Link>
                  </li>
                  <li>
                    Site Anti scam-policy, at{' '}
                    <Link href={'/anti-scam-policy'} underline='none'>
                      <div className={classes.link}> link</div>
                    </Link>
                  </li>
                  <li>
                    Site Cookie Policy, at{' '}
                    <Link href={'/cookie-policy'} underline='none'>
                      <div className={classes.link}> link</div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Why should I register on the service?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Our website administration needs to identify You as a particular user in order You could perform
                  actions on our service and preserve Your results. Example: when You have no registration on our site
                  You can’t create a list of Your favorites, and we can’t do this instead of You.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What can I do without registering?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  All the necessary information is available on the website for Your review. You are able to observe
                  information on the main page of the service, search for humans to chat with, view the list of humans,
                  their profiles and photos. Furthermore, You can send Your requests and proposals to the support
                  service.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Is the registration paid on the website?</div>
              </div>
              <div className={classes.questionBody}>
                <div>Registration on the service is absolutely free of charge.</div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What to do in case I forgot password from my account?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  For this purpose, please, use the password recovery form, that is easily to find in the authorization
                  block. In addition, You can use the feedback form addressing this issue to us.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What to do if I want to change the e-mail address to which my account is registered?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Write a letter to the support service using the feedback form. In Your letter, enter the data
                  confirming that You own the account on our service. We will examine this data when receive the letter,
                  in case this information is true, we will contact You.
                </div>
                <div>
                  Important! After registration, the e-mail which You have linked Your account with is impossible to
                  change.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>My account was stolen, what should I do?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  It is necessary to write a letter about this to the support service of our site via the feedback form
                  or by e-mail. Once we receive Your letter, we will verify all the information provided by You and give
                  You the detailed instructions on Your next actions.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>How can I delete my account?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Write a letter to the support service, using the feedback form or by e-mail. In Your e-mail attach
                  video-message with intention to delete Your account and the reason for removal. You have indicate the
                  ID of your account and e-mail of registration. When we receive the e-mail, we will contact You in
                  order to confirm the removal of Your account.
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ boxShadow: 'none', borderBottom: '1px solid #E7E6E6' }}
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
            <Typography>
              <ol start={2} className={classes.headline}>
                <li> Services and payments</li>
              </ol>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What to do after registration?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  To make Your profile looks more attractive in the eyes of users, You can fill out information about
                  Yourself and upload some photos. After that You can search for users profiles or lists of profiles
                  recommended for viewing. When You find a users who interests You, save their profile in contacts. You
                  can visit the user's profile, this action is noticeable to the opponent and expresses a sign of
                  attention, and, perhaps, the user will write to you soon.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What information can I provide in my profile?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  While using our service, the only information required to provide from You is the e-mail address and
                  password. You give us this information during Your registration. At Your own discretion You can
                  disclose the rest of the information. In order to perform this, You should click on the “edit” buttons
                  in Your profile. Anyway, please, pay Your attention that humans hate liars, so do not include false
                  information in Your profile.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Do I have to upload my photos?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  This action is not mandatory, but when You set a nice picture as Your avatar Your profile becomes more
                  attractive. It is worth to add 3-4 of Your best photos to a public album. On our website it is allowed
                  to upload photos of JPEG and PNG format, with a resolution of at least 720px x 720px and not more than
                  3072px x 1620px, and also no larger than 2 Mb.
                </div>
                <div>
                  The administrators of the website is able to delete Your photos from public album, if they contain
                  pornographic and improper material or indecent exposure.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Does anyone else see my private photos?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  No one can see Your personal album except You. Such private album is necessary so that You can upload
                  photos that are out of reach to all users. You are free to send these photos via a letter or chat to
                  the user You have chosen.
                </div>
                <div>
                  In case, if the user, to whom You sent these photos, found them offensive or such those could violate
                  her rights, she will file a complaint to the administration, so we will ask You to delete this
                  material to avoid penalties.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Why should I visit user profiles?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  You can visit the profile of each user. Every time you do this, users see a notification about it. You
                  also appear on the list of their visitors. In this manner You attract her attention.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Why do I need a list of contacts?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  You can add a users to Your list of contacts. We suggest You to add users with whom you often
                  communicate to this list or You may add those users who interest
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Can I see the guests of my profile?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Yes, sure. If You want to see the guests You can easily do this using a corresponding menu section
                  "Guests." This section captures all visitors to Your profile.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Can I turn off notifications?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Sure, it is possible! In the “Settings” You are free to turn off the visual or sound notifications. To
                  perform this, please, click on Your avatar in the upper right corner and select “Settings”.
                </div>
                <div>Visualization of system notifications is impossible to turn off.</div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What are the particularities of the service?</div>
              </div>
              <div className={classes.questionBody}>
                <div>By the use of our service, You can:</div>
                <ul>
                  <li>Look for users;</li>
                  <li>Demonstrate signs of attention to the user, visiting their profiles;</li>
                  <li>Save the lists of contacts;</li>
                  <li>Track Your profile’s guests;</li>
                  <li>Write letters to the users;</li>
                  <li>Send virtual gifts to users;</li>
                  <li>Track the most interesting news on the website;</li>
                  <li>Chatting in text chat;</li>
                  <li>Chatting in video chat;</li>
                  <li>Send/receive private photo in chat;</li>
                  <li>Send/receive private video in chat;</li>
                  <li>Send/receive private photo in letters;</li>
                  <li>Send/receive private video in letters;</li>
                </ul>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Which services are paid? And how much do they cost?</div>
              </div>
              <div className={classes.questionBody}>
                <div>The list of paid services includes:</div>
                <strong>Letters:</strong>
                <ul className={classes.styledOl}>
                  <li>
                    Writing letters to users - {creditsMessageSend} credits. <br />
                    <em>
                      Note: The first letter you write to a new user or the first letter you receive from a new user in
                      a dialogue is free of charge! Any subsequent actions, such as responses or reading responses to
                      these initial letters, are subject to the usual credit cost. Sending photos in letters is also
                      free of charge.
                    </em>
                  </li>
                  <li>Reading letters from users - {creditsMessageOpen} credits.</li>
                  <li>Open private photos in letters (beyond the first photo) - {creditsMessagePhotoOpen} credits.</li>
                  <li>Sending private video in letters - {creditsMessageVideoSend} credits.</li>
                  <li>Open private video in letters - {creditsMessageVideoOpen} credits.</li>
                </ul>
                <strong>Gifts:</strong>
                <ul className={classes.styledOl}>
                  <li>Sending virtual gifts to users - gift price is indicated in credits for each gift.</li>
                </ul>
                <strong>Chat:</strong>
                <ul className={classes.styledOl}>
                  <li>Chatting in text chat - {creditsChatTextMin} credits per minute.</li>
                  <li>Chatting in video chat - {creditsChatVideoMin} credits per minute.</li>
                  <li>Sending private photos in chat - {creditsChatPhotoSend} credits.</li>
                  <li>Open private photos in chat - {creditsChatPhotoOpen} credit.</li>
                  <li>Sending private video in chat - {creditsChatVideoSend} credits.</li>
                  <li>Open private video in chat - {creditsChatVideoOpen} credits.</li>
                </ul>
                <strong>Dialogs:</strong>
                <ul className={classes.styledOl}>
                  <li>Sending a message in dialogs - {creditsDialogTextSend} credits.</li>
                  <li>Sending private photos in dialogs - {creditsDialogPhotoSend} credits.</li>
                  <li>Open private photos in dialogs - {creditsDialogPhotoOpen} credit.</li>
                  <li>Sending private video in dialogs - {creditsDialogVideoSend} credits.</li>
                  <li>Open private video in dialogs - {creditsDialogVideoOpen} credits.</li>
                  <li>Sending a sticker in dialogs - {creditsStickerSend} credits.</li>
                </ul>
                <strong>Black List:</strong>
                <ul className={classes.styledOl}>
                  <li>Add user to blacklist - {creditsProfilesBlacklistAdd} credits.</li>
                </ul>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>How can I buy credits?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  By clicking on the coin image at the top of the website page, You have the ability to buy the virtual
                  currency of our website, which is called “credit”. Once you click on it, You will go to the list of
                  credit packages. You may choose the one that is suitable for You and then click to buy. Right after
                  this You will be transferred to the payment system.
                </div>
                <div>Please, note, the more credits You buy at a time, the lower the price of each credit is!</div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>How much does 1 credit cost?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  By mischance, we can not accurately answer this question. Credits are sold both in standard and in
                  promotional packages. The cost of each credits depends on the quantity of credits in the package.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Do users have "private" photos? How can I view them?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Of course, every user on the website has the album with his private photos. In case when You want to
                  see all photos of user, You can send to user a request or letter and ask if she/he could provide the
                  photos.
                </div>
                <div>
                  We advise You, to get acquainted with the user, talk to her/his first, try to learn her better and
                  only after this ask for private photos. When the user sends photos together with the letter you are
                  able to review it in incoming letters, if he sends photos using chat you can see it in the chat.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What if I write a letter to a user and he does not answer?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  The administration of our service does not represent or warrant that the user will reply to Your
                  letter. At first, try to be interesting to this user, send her/his a invite to chat.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Can I use the chat if I do not have a camera or microphone?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Sure, You can. You can easily use all types of chats. You should pay Your attention, that during
                  chatting in video chat with no camera, the user will not be able to see You.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What is the difference between text and video chats?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  In the text chat, You can chat using text and smiles only. Video chat provides You more opportunities:
                  You and user can turn on the camera and talk with each other “face to face”. The video chat window is
                  different from the text chat allowing You to enjoy the communication with the user You chose as
                  comfortable as possible. In addition, it should be borne in mind that the more functions a chat has,
                  the higher its cost.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div> Can I send my photos and video to users?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  <div>Yes, of course! The cost is:</div>
                  <ul>
                    <li>Sending private photos in letters - free</li>
                    <li>Sending private photos in chat - {creditsChatPhotoSend} credits;</li>
                    <li>Sending private photos in dialogs - {creditsDialogPhotoSend} credits;</li>
                    <li>Sending private video in letters - {creditsMessageVideoSend} credits;</li>
                    <li>Sending private video in chat - {creditsChatVideoSend} credits;</li>
                    <li>Sending private video in dialogs - {creditsDialogVideoSend} credits;</li>
                  </ul>
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div> Can I receive photos and video from the users?</div>
              </div>
              <div className={classes.questionBody}>
                <div>Yes, of course! The cost is:</div>
                <ul>
                  <li>Open private photos in letters (beyond the first photo) - 2 credits</li>
                  <li>Open private photos in chat - {creditsChatPhotoOpen} credits;</li>
                  <li>Open private photos in dialog - {creditsDialogPhotoOpen} credits;</li>
                  <li>Open private video in letters - {creditsMessageVideoOpen} credits;</li>
                  <li>Open private video in chat - {creditsChatVideoOpen} credits;</li>
                  <li>Open private video in dialogs - {creditsDialogVideoOpen} credits;</li>
                  <li>Sending a sticker in dialogs- {creditsStickerSend} credits;</li>
                </ul>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Where can I view the photo and video received and opened by me from users?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  You are able to view all photos and videos, received and opened by You in the active chat and in
                  letters.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Is there any history of messages in the chat?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  The Chat settings allow to record Your correspondence during the current session of the browser
                  window. In case when You close the browser window or the browser itself, the message history will be
                  deleted. At the same time, every user You had a conversation to is stored in the Your Last Chats.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>If I close the chat window, will it stop?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  After the browser is closed, the chat will stop, the credits will no longer be counted, and the
                  correspondence history will be deleted.
                </div>
                <div>
                  Please, pay Your attention, that at the beginning of every minute of the chat the credits are written
                  off from Your account. So if You close the window when the minute starts, this minute is considered to
                  be paid.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>The video from the video chat is a record, isn’t it?</div>
              </div>
              <div className={classes.questionBody}>
                <div>No, the video image is being transmitted in real time from the interlocutor’s camera.</div>
              </div>

              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>How does the virtual gift I order on the website get to the user?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  As soon as you have ordered a virtual gift for a user, your virtual gift will be added to the inbox of
                  the user.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Can I receive the virtual gift from a user on the web site?</div>
              </div>
              <div className={classes.questionBody}>
                <div>Yes, sure! You can receive virtual gifts from the users.</div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Is it possible to give a virtual gift which is not on the list on the service?</div>
              </div>
              <div className={classes.questionBody}>
                <div>Unfortunately, it is not possible.</div>
              </div>

              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Is it possible to give a gift which is not on the list on the service?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Unfortunately, it is not possible. We work with reliable suppliers and we can only deliver the gifts
                  that are available in the region where the girl lives. If you want to present the gift that is out of
                  the list, You can send a request about it using the feedback form or by e-mail, and we will give
                  careful thought on Your demand. Perhaps we will add the gifts that You have offered to the list in
                  future
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What if after buying the user's contacts, they turn out to be incorrect?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  We examine the contact information details of every user during registration process, so wrong data is
                  not provided by us. In addition, we check the contacts before every time You purchase it.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Does the girl know that I bought her contacts information?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Yes, sure! We always inform the girls that someone from man-users have bought her contact information,
                  moreover, we tell the girls who was that person who has purchased her contacts. All information
                  transferred to You is based on her approval and legislation on the protection of personal data of the
                  country of her residence.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What payment methods and payment systems does the service support?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  On our website, all payments are made by means of ccbill service. You can reach for more detailed
                  information about payment systems supported by this service, if You address their website.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What to do if I have difficulty when buying credits?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  You can describe this issue in written using the feedback form or e-mail. Payment issues are always
                  prioritized, so once we receive Your letter, we will contact You as soon as possible!
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>How long are the credits accrued to the account?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Once the payment service confirmed Your payment, credits are accrued immediately after such
                  confirmation.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Can I return my money?</div>
              </div>
              <div className={classes.questionBody}>
                <div>The license agreement provides all cases, in which we can refund Your invested amounts.</div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ boxShadow: 'none', borderBottom: '1px solid #E7E6E6' }}
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
            <Typography>
              <ol start={3} className={classes.headline}>
                <li>Communication and relationships between participants on the site</li>
              </ol>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Are there participants under the age of 18 on the service?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  You can describe this issue in written using the feedback form or e-mail. Payment issues are always
                  prioritized, so once we receive Your letter, we will contact You as soon as possible!
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Are the users on Your website real?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Yes, of course! All the participants at our site are real user, because each profile is veryfied and
                  approved by the administration of our website.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Do the users on this website know English?</div>
              </div>
              <div className={classes.questionBody}>
                <div>Not all users speak English fluently. But most of the users were studying English at school.</div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Can the information about the user indicated on his profile be untrue?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Under no circumstances! The rules of our site establish the passage of verification for each user
                  after registration.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What to do if I doubt the user’s honesty?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Please, feel free to inform the support service of our website on this situation via the feedback form
                  or by e-mail. After receiving Your letter, we will verify all the information provided, and if the
                  information is confirmed, we will impose penalties to that user.
                </div>
                <div>The license agreement deals with the issue of returning the money spent.</div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What to do if a user asked to send his money?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  If the user asks or demands to send her/him money, do not do it under no circumstances! Only scammers
                  act this way. Don’t forget to write a letter about it to the support service of our website using the
                  feedback form or by e-mail. Once we receive Your letter, we will verify all the information provided,
                  and in case the information is confirmed, we will impose penalties to that user.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Can I meet with a user?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  You must remember, that meetings are possible by mutual consent of the parties only. Before taking
                  such a serious step, we suggest You to discuss with Your beloved one Your intention to meet her/him as
                  well as the details of your meeting.
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ boxShadow: 'none', borderBottom: '1px solid #E7E6E6' }}
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
            <Typography>
              <ol start={4} className={classes.headline}>
                <li>Technical issues</li>
              </ol>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What are the system requirements for the website?</div>
              </div>
              <div className={classes.questionBody}>
                <div>The necessary connection speed is up to 50 Mbps and ping 100ms.</div>
                <div>To use the site it is preferably to use one of the browsers:</div>
                <ul>
                  <li>Edge 10 and higher</li>
                  <li>Opera 88.0 and higher</li>
                  <li>Google Chrome 103.0 and higher</li>
                  <li>Mozilla Firefox 102.0 and higher</li>
                  <li>Safari 15.5 and higher</li>
                </ul>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What if I found an error on the site?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  You should inform the support service of our website on this problem in Your letter using a feedback
                  form or by e-mail. Once we receive letter from You, we will verify all the information provided.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>I want to call and report an error, how do I call you?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  You can write a letter to the support team of our website requesting our contacts details, using a
                  feedback form or by e-mail. Once we receive letter from You, we will contact You directly by phone or
                  we will send You our contact phone number.
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ boxShadow: 'none', borderBottom: '1px solid #E7E6E6' }}
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
            <Typography>
              <ol start={5} className={classes.headline}>
                <li>Decline reasons</li>
              </ol>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What does “Fraud (Stolen, Blocked or Forged)” mean?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  This decline indicates that the transaction was flagged as potentially fraudulent by your card issuer.
                  It’s crucial to contact your bank immediately to clarify and resolve this situation, ensuring your
                  account security.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What should I do if my transaction has been declined due to “Cardholder is over limit”?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  This message means that the transaction exceeds your credit card’s current credit limit. To resolve
                  this, you can try using another credit card or contact your bank to discuss increasing your card
                  limit.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What does “Do not honor” mean on a declined transaction?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  This decline code means that your bank has chosen not to approve the transaction. Please contact your
                  bank directly to find out the specific reason, as they often require confirmation from you to
                  authorize the transaction.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Why was my payment declined for “Security Violation”?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  A “Security Violation” typically indicates that the transaction was flagged by the bank’s fraud
                  detection systems. Ensure your card details are entered correctly and contact your bank to clarify any
                  security concerns.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What should I do if my payment says “Insufficient Funds”?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Check your account balance to ensure you have enough funds to complete the transaction. Sometimes,
                  holds or pending transactions can reduce your available balance.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What does “Multiple Submission” mean?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  This error occurs if a payment attempt is submitted multiple times. Wait a few moments and try again.
                  If it persists, please contact our support.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>My transaction was declined due to “CVV2 Mismatch.” What does this mean?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  The CVV2 code you entered does not match the one on file with your bank. Re-enter your payment
                  information, ensuring the CVV code is correct.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Why was my payment declined as “Invalid Transaction”?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  This typically indicates an issue with the payment gateway or the way the transaction was set up.
                  Contact customer support for more details and assistance.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What does “Score Decline” mean?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Score decline usually refers to internal risk assessments by the payment processor or bank. Contact
                  your bank to discuss their risk parameters.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Why is my credit card labeled as “Invalid”?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Check to ensure all card information is correct and up to date, including the expiration date. If
                  everything appears correct, contact your card issuer for further assistance.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What if there is an “AVS Mismatch”?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Address Verification System (AVS) mismatch occurs when the billing address entered does not match the
                  one on file with your bank. Update your billing address if necessary.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What does “Blocked Country” mean?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Transactions attempted from countries that are blocked by the payment processor or bank will be
                  declined. If you are traveling, inform your bank of your location to avoid this issue.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What should I do if my card is “Expired”?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Replace your payment method with a card that has a valid expiration date.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What if the decline is due to “No such issuer”?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  This means the card number entered does not correspond to a valid issuer. Double-check the card number
                  and try again.
                </div>
              </div>
              <div>For any issues where the reason for a transaction decline is unclear or if you need immediate
                assistance resolving payment problems, please do not hesitate to reach out to our live support. Our team
                is ready to help you understand and address any concerns with your transactions.
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </Container>
  );
};

export default FAQ;
