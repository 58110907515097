import { normalizePatchProfileData } from '@/helpers/helpers';
import PhotosService from '@/services/photos.service';
import ProfilesService from '@/services/profiles.service';
import SettingsService from '@/services/settings.service';
import Socket from '@/services/Socket';
import WalletsService from '@/services/wallets.service';
import { DialogMessage, IAcquiring, IBlackList, ISettings, UserDialog, UserProfile } from '@/types/user-profile.types';
import { all, call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { toggleModalOpen, toggleSnackbarOpen } from '../ui/ui.actions';
import {
  deleteBookmarksFailure,
  deleteBookmarksRequest,
  deleteBookmarksSuccess,
  deletePhotosRequest,
  deletePhotosSuccess,
  deletePublicPhotosFailure,
  getAvailableUsersFailure,
  getAvailableUsersRequest,
  getAvailableUsersSuccess,
  getBookmarksFailure,
  getBookmarksRequest,
  getBookmarksSuccess,
  getPhotosFailure,
  getPhotosRequest,
  getPhotosSuccess,
  getProfilePhotosRequest,
  getProfilePhotosSuccess,
  getProfilePhotosFailure,
  getSearchUsersFailure,
  getSearchUsersRequest,
  getSearchUsersSuccess,
  postPhotosFailure,
  postPhotosRequest,
  postPhotosSuccess,
  setBookmarksFailure,
  setBookmarksRequest,
  setBookmarksSuccess,
  setSendMessageRequest,
  setSendMessageSuccess,
  updateBalanceRequest,
  updateBalanceSuccess,
  updateBalanceFailed,
  chargeBalanceRequest,
  chargeBalanceSuccess,
  chargeBalanceFailed,
  updateCurrentUserFailure,
  updateCurrentUserRequest,
  updateCurrentUserSuccess,
  getSettingsRequest,
  getSettingsSuccess,
  getSettingsFailed,
  userBalanceChargedSuccess,
  changeUserPasswordRequest,
  changeUserEmailRequest,
  setSendPhotoMessageRequest,
  setSendPhotoMessageSuccess,
  revealPhotoMessageRequest,
  revealPhotoMessageSuccess,
  updateAvatarRequest,
  getUsersDialogsSuccess,
  getUsersDialogsRequest,
  getDialogCountersSuccessAction,
  updateInappRequest,
  updateInappSuccess,
  updateInappFailed,
  filterInappRequest,
  filterInappSuccess,
  getAcquiringRequest,
  getAcquiringSuccess,
  getAcquiringFailed,
  updateQuestionsRequest,
  filterQuestionsRequest,
  updateQuestionsSuccess,
  updateQuestionsFailed,
  filterQuestionsSuccess,
  closeQuestionsRequest,
  getEmailEventsBlacklistRequest,
  getEmailEventsBlacklistSuccess,
  getEmailEventsBlacklistFailed,
  setBlockedByMeFailure,
  setBlockedByMeRequest,
  setBlockedByMeSuccess,
  deleteBlockedByMeFailure,
  deleteBlockedByMeRequest,
  deleteBlockedByMeSuccess,
  getBLockedUsersFailure,
  getBLockedUsersRequest,
  getBLockedUsersSuccess,
  getVideosRequest,
  getVideosSuccess,
  getVideosFailure,
  postVideosRequest,
  postVideosSuccess,
  postVideosFailure,
  deleteVideosRequest,
  deleteVideosSuccess,
  deletePublicVideosFailure,
  revealVideoMessageRequest,
  revealVideoMessageSuccess,
  setSendVideoMessageRequest,
  getProfileVideosRequest,
  getProfileVideosSuccess,
  getProfileVideosFailure,
  changeUserEmailSuccess,
  getDialogCountersAction,
} from './users.actions';
import {
  IInappMessagePayload,
  IQuestions,
  IUserPhotosData,
  UserPhotosDTO,
  IUserVideosData,
  UserVideosDTO
} from './users.dto';
import {
  CHARGE_BALANCE_REQUEST,
  DELETE_BOOKMARKS_REQUEST,
  DELETE_USER_PHOTOS_REQUEST,
  GET_AVAILABLE_USERS_REQUEST,
  GET_BOOKMARKS_REQUEST,
  GET_PROFILE_PHOTOS_REQUEST,
  GET_SEARCH_USERS_REQUEST,
  GET_SETTINGS_REQUEST,
  GET_USER_PHOTOS_REQUEST,
  POST_USER_PHOTOS_REQUEST,
  SEND_MESSAGE_TO_CHAT_REQUEST,
  SET_BOOKMARKS_REQUEST,
  SET_BLOCKED_BY_ME_REQUEST,
  DELETE_BLOCKED_BY_ME_REQUEST,
  GET_BLOCKED_USERS_REQUEST,
  UPDATE_BALANCE_REQUEST,
  UPDATE_CURRENT_USER_PROFILE_REQUEST,
  CHANGE_USER_PASSWORD,
  SEND_PHOTO_MESSAGE_TO_CHAT_REQUEST,
  REVEAL_PHOTO_CHAT_REQUEST,
  UPDATE_CURRENT_USER_AVATAR_REQUEST,
  GET_USERS_DIALOGS_REQUEST,
  GET_DIALOG_COUNTERS,
  UPDATE_INAPP_REQUEST,
  FILTER_INAPP_REQUEST,
  GET_ACQUIRING_REQUEST,
  UPDATE_QUESTIONS_REQUEST,
  FILTER_QUESTIONS_REQUEST,
  CLOSE_QUESTIONS_REQUEST,
  GET_EMAIL_EVENTS_BLACKLIST_REQUEST,
  GET_PROFILE_VIDEOS_REQUEST,
  DELETE_USER_VIDEOS_REQUEST,
  GET_USER_VIDEOS_REQUEST,
  POST_USER_VIDEOS_REQUEST,
  SEND_VIDEO_MESSAGE_TO_CHAT_REQUEST,
  REVEAL_VIDEO_CHAT_REQUEST,
  CHANGE_USER_EMAIL,
} from './users.types';
import { StorageService } from '@/services/storage.service';
import AcquiringService from '@/services/acquiring.service';
import EmailEventsBlacklistService from '@/services/emailEventsBlacklist.service';
import { EmailEventsBlacklist } from './users.reducer';
import VideosService from '@/services/videos.service';

function* getPhotosWorkerSaga(action: ReturnType<typeof getPhotosRequest>) {
  const { viewType, offset } = action;
  try {
    const photos: IUserPhotosData = yield call(() => PhotosService.getPhotos(offset, viewType));
    yield put(getPhotosSuccess(viewType, photos));
  } catch (e) {
    console.log('e', e);
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(getPhotosFailure(viewType, e));
  }
}

function* postPhotosWorkerSaga(action: ReturnType<typeof postPhotosRequest>) {
  const { file, viewType } = action;
  try {
    const photo: UserPhotosDTO = yield call(() => PhotosService.postPhoto(file, viewType));
    yield put(postPhotosSuccess(viewType, photo));
    yield put(toggleSnackbarOpen('Photo has been uploaded', 'success'));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(postPhotosFailure(viewType, e));
  }
}

function* deletePhotosWorkerSaga(action: ReturnType<typeof deletePhotosRequest>) {
  const { id, viewType } = action;
  try {
    yield PhotosService.deletePhoto(id, viewType);

    yield put(toggleSnackbarOpen('Photo has been deleted', 'success'));
    yield put(deletePhotosSuccess(viewType, id));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(deletePublicPhotosFailure(viewType, e));
  }
}

function* getSearchedUsersWorkerSaga(action: ReturnType<typeof getSearchUsersRequest>) {
  try {
    const { config } = action;

    const users: UserProfile[] = yield call(() => ProfilesService.getUsers(config));

    yield put(getSearchUsersSuccess(users));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(getSearchUsersFailure(e));
  }
}

function* updateCurrentAvatarWorkerSaga(action: ReturnType<typeof updateAvatarRequest>) {
  try {
    const { body } = action;

    const patchedUser: UserProfile = yield call(() => ProfilesService.updateProfileAvatar(body));

    yield put(updateCurrentUserSuccess(patchedUser as unknown as UserProfile));
    yield put(toggleSnackbarOpen('Settings saved', 'success'));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(updateCurrentUserFailure(e));
  }
}

function* updateCurrentUserWorkerSaga(action: ReturnType<typeof updateCurrentUserRequest>) {
  try {
    const { user: patchUserData } = action;

    const normalizedData = normalizePatchProfileData(patchUserData);

    const patchedUser: UserProfile = yield call(() => ProfilesService.updateProfile(normalizedData as UserProfile));

    yield put(updateCurrentUserSuccess(patchedUser as unknown as UserProfile));
    yield put(toggleSnackbarOpen('Settings saved', 'success'));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(updateCurrentUserFailure(e));
  }
}

function* getAvailableUsersWorkerSaga(action: ReturnType<typeof getAvailableUsersRequest>) {
  try {
    const { config } = action;
    const token = StorageService.getToken();
    if (!token) {
      return;
    }

    const users: UserProfile[] = yield call(() => ProfilesService.getAvailableUsers(config));

    yield put(getAvailableUsersSuccess(users));
  } catch (e) {
    // yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(getAvailableUsersFailure(e));
  }
}

function* getUsersDialogsWorkerSaga(action: ReturnType<typeof getUsersDialogsRequest>) {
  try {
    const { config } = action;

    const dialogs: UserDialog = yield call(() => ProfilesService.getDialogs(config.offset));

    yield put(getUsersDialogsSuccess(dialogs));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(getAvailableUsersFailure(e));
  }
}

function* getDialogCountersSaga(action: ReturnType<typeof getDialogCountersAction>) {
  try {
    const response = yield call(() => ProfilesService.getDialogCounters());

    yield put(getDialogCountersSuccessAction(response));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request closed attachment', 'error'));
  }
}

function* getBalanceWorkerSaga(action: ReturnType<typeof updateBalanceRequest>) {
  try {
    const wallet: { balance: number } = yield call(() => WalletsService.getBalance());

    yield put(updateBalanceSuccess(wallet));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(updateBalanceFailed(e));
  }
}

function* getInappWorkerSaga(action: ReturnType<typeof updateInappRequest>) {
  try {
    const data: IInappMessagePayload = yield call(() => ProfilesService.getInappMessage());

    yield put(updateInappSuccess({ inAppMessages: data }));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(updateInappFailed(e));
  }
}

function* filterInappWorkerSaga(action: ReturnType<typeof filterInappRequest>) {
  try {
    yield call(() => ProfilesService.filterInappMessage(action.inappDistributionId));

    yield put(filterInappSuccess({ inappDistributionId: action.inappDistributionId }));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(updateInappFailed(e));
  }
}

function* getQuestionsWorkerSaga(action: ReturnType<typeof updateQuestionsRequest>) {
  try {
    const data: IQuestions[] = yield call(() => ProfilesService.getQuestions());
    yield put(updateQuestionsSuccess({ questions: data }));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(updateQuestionsFailed(e));
  }
}

function* filterQuestionsWorkerSaga(action: ReturnType<typeof filterQuestionsRequest>) {
  try {
    yield call(() => ProfilesService.requestRewardForQuestions(action.id));
    yield put(filterQuestionsSuccess({ id: action.id }));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(updateQuestionsFailed(e));
  }
}

function* closeQuestionsWorkerSaga(action: ReturnType<typeof closeQuestionsRequest>) {
  try {
    yield call(() => ProfilesService.requestCloseForEverQuestions(action.id));
    yield put(filterQuestionsSuccess({ id: action.id }));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(updateQuestionsFailed(e));
  }
}

function* chargeBalanceWorkerSaga(action: ReturnType<typeof chargeBalanceRequest>) {
  try {
    const wallet: { balance: number } = yield call(() =>
      WalletsService.chargeBalance({
        config: action.payload.config,
        targetProfileId: action.payload.toProfileId,
      })
    );

    yield put(chargeBalanceSuccess(wallet));
    yield put(userBalanceChargedSuccess({ profileId: action.payload.toProfileId }));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(chargeBalanceFailed(e));
  }
}

function* getSettingsWorkerSaga(action: ReturnType<typeof getSettingsRequest>) {
  try {
    const data: ISettings = yield call(() => SettingsService.getSettings());

    yield put(getSettingsSuccess(data));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(getSettingsFailed(e));
  }
}

function* getEmailEventsBlacklistWorkerSaga(action: ReturnType<typeof getEmailEventsBlacklistRequest>) {
  try {
    const data: EmailEventsBlacklist[] = yield call(() => EmailEventsBlacklistService.getEmailEventsBlacklist());
    yield put(getEmailEventsBlacklistSuccess(data));
  } catch (e) {
    console.log('e', e);
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(getEmailEventsBlacklistFailed(e));
  }
}

function* getAcquiringWorkerSaga(action: ReturnType<typeof getAcquiringRequest>) {
  try {
    const data: IAcquiring[] = yield call(() => AcquiringService.getAcquiring());

    yield put(getAcquiringSuccess(data));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(getAcquiringFailed(e));
  }
}

function* changeUserPassword(action: ReturnType<typeof changeUserPasswordRequest>) {
  try {
    const res = yield call(() => SettingsService.changePassword(action.password));

    if (res) {
      yield put(toggleSnackbarOpen('Password has been changed', 'success'));
    }
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(getSettingsFailed(e));
  }
}

function* changeUserEmail(action: ReturnType<typeof changeUserEmailRequest>) {
  try {
    const res = yield call(() => SettingsService.changeEmail(action.email));

    if (res) {
      yield put(toggleSnackbarOpen('Email has been changed', 'success'));
      yield put(changeUserEmailSuccess(action.email));
    }
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(getSettingsFailed(e));
  }
}

function* getBookmarksSaga(action: ReturnType<typeof getBookmarksRequest>) {
  try {
    const { config } = action;
    const users: UserProfile[] = yield call(() => ProfilesService.getBookmarks(config));

    yield put(getBookmarksSuccess(users));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(getBookmarksFailure(e));
  }
}

function* getBLockedUsersSaga(action: ReturnType<typeof getBLockedUsersRequest>) {
  try {
    const users: IBlackList[] = yield call(() => ProfilesService.getBLockedUsers());

    yield put(getBLockedUsersSuccess(users));
  } catch (e) {
    // yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(getBLockedUsersFailure(e));
  }
}

function* setBlockedByMeSaga(action: ReturnType<typeof setBlockedByMeRequest>) {
  try {
    const { profileId } = action;
    const user: IBlackList = yield call(() => ProfilesService.setBLockedByMe(profileId));

    yield put(setBlockedByMeSuccess(user));
  } catch (e) {
    if (e.message === 'Profile blocked by yourself') {
      yield put(
        toggleModalOpen('You have blocked that user, you can go to profile and unblock', 'error', action.profileId)
      );
    }
    yield put(toggleSnackbarOpen(e.message, 'error'));
    yield put(setBlockedByMeFailure(e));
  }
}

function* deleteBlockedByMeSaga(action: ReturnType<typeof deleteBlockedByMeRequest>) {
  try {
    const { profileId } = action;
    yield call(() => ProfilesService.deleteBLockedByMe(profileId));

    yield put(deleteBlockedByMeSuccess(profileId));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(deleteBlockedByMeFailure(e));
  }
}

function* setBookmarksSaga(action: ReturnType<typeof setBookmarksRequest>) {
  try {
    const { config } = action;

    yield call(() => ProfilesService.setBookmarks(config.id));

    yield put(setBookmarksSuccess(config));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(setBookmarksFailure(e));
  }
}

function* deleteBookmarksSaga(action: ReturnType<typeof deleteBookmarksRequest>) {
  try {
    const { config } = action;

    yield call(() => ProfilesService.deleteBookmarks(config.id));

    yield put(deleteBookmarksSuccess(config.id));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(deleteBookmarksFailure(e));
  }
}

function* sendMessageSaga(action: ReturnType<typeof setSendMessageRequest>) {
  try {
    const { payload } = action;

    const blockedUser = yield call(() => ProfilesService.getSingleBlockedRequest(payload.toProfileId));

    if (blockedUser) {
      if (blockedUser.ownerProfileId === payload.toProfileId) {
        yield put(toggleSnackbarOpen('You have been blocked by that user', 'error'));
      } else {
        yield put(toggleSnackbarOpen('You have blocked that user', 'error'));
        yield put(
          toggleModalOpen(
            'You have blocked that user, you can go to profile and unblock',
            'error',
            action.payload.toProfileId
          )
        );
      }
      return;
    }

    if (payload?.isSticker) {
      if (!payload.isStaff) {
        const data = yield call(() => ProfilesService.payForSticker(payload.toProfileId, payload.sticker.id));
      }
      payload.text = `::sticker::url::${payload.sticker.image}`
    }

    if (Socket && Socket.socket) {
      Socket.emit('msg:send', payload);
    }
    // yield put(setSendMessageSuccess(payload));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
  }
}

function* revealPhotoMessageSaga(action: ReturnType<typeof revealPhotoMessageRequest>) {
  try {
    const { photoId, date, closeHandler } = action.payload;

    const data = yield call(() => ProfilesService.payForPhoto(photoId));

    yield put(
      revealPhotoMessageSuccess({
        toProfileId: data.profileId,
        date: date,
        img: {
          photoId: data.photoId,
          origin: data.origin,
          large: data.large,
          small: data.small,
        },
        closeHandler,
      })
    );
    yield closeHandler();
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
  }
}

function* sendPhotoMessageSaga(action: ReturnType<typeof setSendPhotoMessageRequest>) {
  try {
    const { payload } = action;

    if (payload.isDialog) {
      yield call(() => ProfilesService.dialogPhotoSend(payload.toProfileId, payload.photoId));
      yield action.payload.closeHandler();
      return;
    }

    if (!action.payload.isStaff) {
      yield call(() => ProfilesService.chatPhotoSend(payload.toProfileId));
    }

    if (Socket && Socket.socket) {
      Socket.emit('photo:send', payload);
    }
    yield action.payload.closeHandler();
  } catch (e) {
    if (e.message === 'Profile blocked by yourself') {
      yield put(
        toggleModalOpen(
          'You have blocked that user, you can go to profile and unblock',
          'error',
          action.payload.toProfileId
        )
      );
    }
    yield put(toggleSnackbarOpen(e.message, 'error'));
  }
}

function* getUserPhotosWorkerSaga(action: ReturnType<typeof getProfilePhotosRequest>) {
  const { profileId, viewType } = action;
  try {
    const photos: IUserPhotosData = yield call(() => PhotosService.getUserPhotos(profileId, viewType));
    yield put(getProfilePhotosSuccess(viewType, photos, profileId));
  } catch (e) {
    console.log('e', e);
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(getProfilePhotosFailure(viewType, e));
  }
}

function* getVideosWorkerSaga(action: ReturnType<typeof getVideosRequest>) {
  const { viewType, offset } = action;
  try {
    const videos: IUserVideosData = yield call(() => VideosService.getVideos(offset, viewType));
    yield put(getVideosSuccess(viewType, videos));
  } catch (e) {
    console.log('e', e);
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(getVideosFailure(viewType, e));
  }
}

function* postVideosWorkerSaga(action: ReturnType<typeof postVideosRequest>) {
  const { file, viewType } = action;
  try {
    const signedUrl = yield call(() => VideosService.getSignedUrl(viewType));
    const video: UserVideosDTO = yield call(() => VideosService.postVideo(signedUrl.uploadUrl, file));
    const changedVideo: UserVideosDTO = yield call(() => VideosService.changeVideoStatus(signedUrl.video.id));

    yield put(postVideosSuccess(viewType, changedVideo));
    yield put(toggleSnackbarOpen('Video has been uploaded', 'success'));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(postVideosFailure(viewType, e));
  }
}

function* deleteVideosWorkerSaga(action: ReturnType<typeof deleteVideosRequest>) {
  const { id, viewType } = action;
  try {
    yield VideosService.deleteVideo(id, viewType);

    yield put(toggleSnackbarOpen('Video has been deleted', 'success'));
    yield put(deleteVideosSuccess(viewType, id));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(deletePublicVideosFailure(viewType, e));
  }
}


function* revealVideoMessageSaga(action: ReturnType<typeof revealVideoMessageRequest>) {
  try {
    const { videoId, date, closeHandler } = action.payload;

    const data = yield call(() => ProfilesService.payForVideo(videoId));

    yield put(
      revealVideoMessageSuccess({
        toProfileId: data.profileId,
        date: date,
        video: {
          videoId: data.videoId,
          origin: data.origin,
          thumbnail: data.thumbnail,
        },
        closeHandler,
      })
    );
    yield closeHandler();
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
  }
}

function* sendVideoMessageSaga(action: ReturnType<typeof setSendVideoMessageRequest>) {
  try {
    const { payload } = action;

    if (payload.isDialog) {
      yield call(() => ProfilesService.dialogVideoSend(payload.toProfileId, payload.videoId, payload.origin, payload.thumbnail));
      yield action.payload.closeHandler();
      return;
    }

    if (!action.payload.isStaff) {
      yield call(() => ProfilesService.chatVideoSend(payload.toProfileId));
    }

    if (Socket && Socket.socket) {
      Socket.emit('video:send', payload);
    }
    yield action.payload.closeHandler();
  } catch (e) {
    if (e.message === 'Profile blocked by yourself') {
      yield put(
        toggleModalOpen(
          'You have blocked that user, you can go to profile and unblock',
          'error',
          action.payload.toProfileId
        )
      );
    }
    yield put(toggleSnackbarOpen(e.message, 'error'));
  }
}

function* getUserVideosWorkerSaga(action: ReturnType<typeof getProfileVideosRequest>) {
  const { profileId, viewType } = action;
  try {
    const videos: IUserVideosData = yield call(() => VideosService.getUserVideos(profileId, viewType));
    yield put(getProfileVideosSuccess(viewType, videos, profileId));
  } catch (e) {
    console.log('e', e);
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(getProfileVideosFailure(viewType, e));
  }
}

function* setSendMessage() {
  yield takeEvery(SEND_MESSAGE_TO_CHAT_REQUEST, sendMessageSaga);
}

function* setSendPhotoMessage() {
  yield takeEvery(SEND_PHOTO_MESSAGE_TO_CHAT_REQUEST, sendPhotoMessageSaga);
}

function* revealPhotoMessageWatcherSage() {
  yield takeEvery(REVEAL_PHOTO_CHAT_REQUEST, revealPhotoMessageSaga);
}

function* setSendVideoMessage() {
  yield takeEvery(SEND_VIDEO_MESSAGE_TO_CHAT_REQUEST, sendVideoMessageSaga);
}

function* revealVideoMessageWatcherSage() {
  yield takeEvery(REVEAL_VIDEO_CHAT_REQUEST, revealVideoMessageSaga);
}

function* updateCurrentUserWatcherSaga() {
  yield takeEvery(UPDATE_CURRENT_USER_PROFILE_REQUEST, updateCurrentUserWorkerSaga);
}

function* updateCurrentAvatarWatcherSaga() {
  yield takeEvery(UPDATE_CURRENT_USER_AVATAR_REQUEST, updateCurrentAvatarWorkerSaga);
}

function* getSearchUsers() {
  yield takeLatest(GET_SEARCH_USERS_REQUEST, getSearchedUsersWorkerSaga);
}

function* getPhotos() {
  yield takeEvery(GET_USER_PHOTOS_REQUEST, getPhotosWorkerSaga);
}

function* getProfilePhotos() {
  yield takeEvery(GET_PROFILE_PHOTOS_REQUEST, getUserPhotosWorkerSaga);
}

function* postPhotos() {
  yield takeEvery(POST_USER_PHOTOS_REQUEST, postPhotosWorkerSaga);
}

function* deletePhotos() {
  yield takeEvery(DELETE_USER_PHOTOS_REQUEST, deletePhotosWorkerSaga);
}

function* getVideos() {
  yield takeEvery(GET_USER_VIDEOS_REQUEST, getVideosWorkerSaga);
}

function* getProfileVideos() {
  yield takeEvery(GET_PROFILE_VIDEOS_REQUEST, getUserVideosWorkerSaga);
}

function* postVideos() {
  yield takeEvery(POST_USER_VIDEOS_REQUEST, postVideosWorkerSaga);
}

function* deleteVideos() {
  yield takeEvery(DELETE_USER_VIDEOS_REQUEST, deleteVideosWorkerSaga);
}

function* getAvailableUsers() {
  yield takeLatest(GET_AVAILABLE_USERS_REQUEST, getAvailableUsersWorkerSaga);
}

function* getUsersDialogs() {
  yield takeLatest(GET_USERS_DIALOGS_REQUEST, getUsersDialogsWorkerSaga);
}

function* getDialogCounters() {
  yield takeEvery(GET_DIALOG_COUNTERS, getDialogCountersSaga);
}

function* getBookmarks() {
  yield takeLatest(GET_BOOKMARKS_REQUEST, getBookmarksSaga);
}

function* setBookmarks() {
  yield takeLatest(SET_BOOKMARKS_REQUEST, setBookmarksSaga);
}

function* deleteBookmarks() {
  yield takeLatest(DELETE_BOOKMARKS_REQUEST, deleteBookmarksSaga);
}

function* setBlockedByMe() {
  yield takeLatest(SET_BLOCKED_BY_ME_REQUEST, setBlockedByMeSaga);
}

function* getBLockedUsers() {
  yield takeLatest(GET_BLOCKED_USERS_REQUEST, getBLockedUsersSaga);
}

function* deleteBlockedByMe() {
  yield takeLatest(DELETE_BLOCKED_BY_ME_REQUEST, deleteBlockedByMeSaga);
}

function* getWalletBalance() {
  yield takeEvery(UPDATE_BALANCE_REQUEST, getBalanceWorkerSaga);
}

function* getInapp() {
  yield takeEvery(UPDATE_INAPP_REQUEST, getInappWorkerSaga);
}

function* filterInapp() {
  yield takeEvery(FILTER_INAPP_REQUEST, filterInappWorkerSaga);
}

function* getQuestions() {
  yield takeEvery(UPDATE_QUESTIONS_REQUEST, getQuestionsWorkerSaga);
}

function* filterQuestions() {
  yield takeEvery(FILTER_QUESTIONS_REQUEST, filterQuestionsWorkerSaga);
}

function* closeQuestions() {
  yield takeEvery(CLOSE_QUESTIONS_REQUEST, closeQuestionsWorkerSaga);
}

function* chargeBalance() {
  yield takeEvery(CHARGE_BALANCE_REQUEST, chargeBalanceWorkerSaga);
}

function* getSettings() {
  yield takeEvery(GET_SETTINGS_REQUEST, getSettingsWorkerSaga);
}

function* getEmailEventsBlacklist() {
  yield takeEvery(GET_EMAIL_EVENTS_BLACKLIST_REQUEST, getEmailEventsBlacklistWorkerSaga);
}

function* getAcquiring() {
  yield takeEvery(GET_ACQUIRING_REQUEST, getAcquiringWorkerSaga);
}

function* changePassword() {
  yield takeEvery(CHANGE_USER_PASSWORD, changeUserPassword);
}

function* changeEmail() {
  yield takeEvery(CHANGE_USER_EMAIL, changeUserEmail);
}

const userSagas = [
  fork(updateCurrentUserWatcherSaga),
  fork(getSearchUsers),
  fork(getPhotos),
  fork(postPhotos),
  fork(deletePhotos),
  fork(getVideos),
  fork(postVideos),
  fork(deleteVideos),
  fork(getAvailableUsers),
  fork(setSendMessage),
  fork(getProfilePhotos),
  fork(getProfileVideos),
  fork(getBookmarks),
  fork(setBookmarks),
  fork(deleteBookmarks),
  fork(getWalletBalance),
  fork(getSettings),
  fork(chargeBalance),
  fork(changePassword),
  fork(changeEmail),
  fork(setSendPhotoMessage),
  fork(revealPhotoMessageWatcherSage),
  fork(setSendVideoMessage),
  fork(revealVideoMessageWatcherSage),
  fork(updateCurrentAvatarWatcherSaga),
  fork(getUsersDialogs),
  fork(getDialogCounters),
  fork(getInapp),
  fork(filterInapp),
  fork(getAcquiring),
  fork(getQuestions),
  fork(filterQuestions),
  fork(closeQuestions),
  fork(getEmailEventsBlacklist),
  fork(setBlockedByMe),
  fork(deleteBlockedByMe),
  fork(getBLockedUsers),
];

export default userSagas;
