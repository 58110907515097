import { IBlackList, UserDialog, UserProfile } from '@/types/user-profile.types';
import { HttpService, WsService } from './http.service';
import { ErrorWrapper } from './utils';
import { CountersShape } from './mail.service';
import { StorageService } from './storage.service';
import { IInappMessagePayload, IQuestions } from '@/app/users/users.dto';

function transformObjectToQueryParams(obj) {
  let queryParams = [];

  for (let key in obj) {
    let value = obj[key];

    if (value !== null && value !== undefined && value !== '' && value !== false) {
      if (Array.isArray(value)) {
        let fromParam = `${key}From=${value[0]}`;
        let toParam = `${key}To=${value[1]}`;
        queryParams.push(fromParam);
        queryParams.push(toParam);
      } else if (key === 'withWebcam' && value === true) {
        queryParams.push('webcam=hd');
      } else if (key === 'isCamHD' && value === true) {
        queryParams.push('webcam=fullhd');
      } else {
        queryParams.push(`${key}=${value}`);
      }
    }
  }

  return queryParams.join('&');
}

export default class ProfilesService {
  static get entity(): string {
    return 'profiles';
  }

  static get availableEntity(): string {
    return 'profiles/online';
  }

  static get bookmarksEntity(): string {
    return 'bookmarks/profiles';
  }

  static get blockedByMeEntity(): string {
    return 'profiles-blacklist';
  }

  static get blockedUsersEntity(): string {
    return 'profiles-blacklist';
  }

  static get bookmarksUpdateEntity(): string {
    return 'bookmarks';
  }

  static get pinnedEntity(): string {
    return 'dialogs/pin/profile';
  }

  static get invitesUrl(): string {
    return '/dialogs/autoinvite';
  }

  static async getAllProfilesByPageId(id: string): Promise<{ data: UserProfile[]; total: number }> {
    try {
      const response = await HttpService.get(`/${this.entity}?pageId=${id}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getProfileByPageId(id: string): Promise<UserProfile> {
    try {
      const response = await HttpService.get(`/${this.entity}/page/${id}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getProfileById(id: string): Promise<UserProfile> {
    try {
      const token = StorageService.getToken();
      if (token) {
        const response = await HttpService.get(`/${this.entity}/${id}`);
        return response.data;
      } else {
        const response = await HttpService.get(`/public/${this.entity}/${id}`);
        return response.data.profile;
      }
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getProfilePhotosById(id: string): Promise<any> {
    try {
      const response = await HttpService.get(`/public/${this.entity}/${id}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getProfileDialogById(id: string, offset: number = 0): Promise<UserDialog> {
    try {
      const response = await HttpService.get(`/dialogs/profiles/${id}?offset=${offset}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getDialogs(offset: number = 0, limit: number = 20): Promise<UserDialog> {
    try {
      const response = await HttpService.get(`/dialogs/?offset=${offset}&limit=${limit}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getDialogInvites(): Promise<UserDialog> {
    try {
      const response = await HttpService.get(`${this.invitesUrl}`);

      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async setDialogMessageSeen(messageId: string): Promise<UserDialog> {
    try {
      const response = await HttpService.post(`/dialogs/${messageId}/seen`);

      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getInappMessage(): Promise<IInappMessagePayload> {
    try {
      const token = StorageService.getToken();
      if (!token) {
        return;
      }
      const response = await HttpService.get('/inapp-messages');
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async filterInappMessage(inappDistributionId: string): Promise<IInappMessagePayload> {
    try {
      const filterData = await HttpService.post(`/inapp-messages/seen`, { ids: [inappDistributionId] });
      return filterData.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getQuestions(): Promise<IQuestions[]> {
    try {
      const token = StorageService.getToken();
      if (!token) {
        return;
      }
      const response = await HttpService.get('/cpa-questions');
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async requestRewardForQuestions(id: string): Promise<any> {
    try {
      const token = StorageService.getToken();
      if (!token) {
        return;
      }
      const response = await HttpService.post('/cpa-questions/', {
        cpaQuestionsId: id,
      });

      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async requestCloseForEverQuestions(id: string): Promise<any> {
    try {
      const token = StorageService.getToken();
      if (!token) {
        return;
      }
      await HttpService.post(`/cpa-questions/${id}/close-forever`, {});
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async guestMark(id: string): Promise<void> {
    try {
      const token = StorageService.getToken();
      if (!token) {
        return;
      }
      await HttpService.post(`/guests`, { profileId: id });
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getUsers(_config: object): Promise<UserProfile[]> {
    let config = Object.assign({}, _config);

    try {
      const params = `${transformObjectToQueryParams(config)}`;

      const response = await HttpService.get(`/${this.entity}${params.length ? `?${params}&limit=100` : '?limit=100'}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async updateProfileAvatar(data: { photoId: string }): Promise<UserProfile> {
    try {
      const response = await HttpService.patch(`/${this.entity}/avatar`, data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async updateProfile(data: UserProfile): Promise<UserProfile> {
    try {
      const response = await HttpService.patch(`/${this.entity}`, data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getAvailableUsers(_config: object): Promise<UserProfile[]> {
    try {
      const token = StorageService.getToken();
      if (!token) {
        return [];
      }
      const response = await HttpService.get(`/${this.availableEntity}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getBookmarks(_config: object): Promise<UserProfile[]> {
    try {
      const response = await HttpService.get(`/${this.bookmarksEntity}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async setBLockedByMe(profileId: string): Promise<IBlackList> {
    try {
      const response = await HttpService.post(`/${this.blockedByMeEntity}`, { profileId });
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.message : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getBLockedUsers(): Promise<IBlackList[]> {
    try {
      const response = await HttpService.get(`/${this.blockedUsersEntity}`);
      return response.data.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.message : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async deleteBLockedByMe(profileId: string): Promise<string> {
    try {
      await HttpService.delete(`/${this.blockedByMeEntity}/profile/${profileId}`);
      return profileId;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.message : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async sendDialogStickerMessage(data: { receiverId: string; stickerId: string }): Promise<void> {
    try {
      const response = await HttpService.post(`/dialogs/sticker`, data);

      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.message : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async sendDialogMessage(data: { receiverId: string; text: string }): Promise<void> {
    try {
      const response = await HttpService.post(`/dialogs/text`, data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.message : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async setBookmarks(profileId: string): Promise<void> {
    try {
      const data = {
        profileId,
      };
      const response = await HttpService.post(`/${this.bookmarksUpdateEntity}`, data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.message : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async deleteBookmarks(profileId: string): Promise<void> {
    try {
      const response = await HttpService.delete(`/${this.bookmarksUpdateEntity}/${profileId}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.message : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getSingleBlockedRequest(profileId: string): Promise<void> {
    try {
      const response = await HttpService.get(`/profiles-blacklist/is-blocked/profile/${profileId}`);

      return response.data;
    } catch (error: any) {
      if (error.response.status === 404) {
        return null;
      }
      const message = error.response.data ? error.response.data.message : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async setPinned(profileId: string): Promise<void> {
    try {
      const data = {
        profileId,
      };
      const response = await HttpService.post(`/${this.pinnedEntity}/${profileId}`, data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async deletePinned(profileId: string): Promise<void> {
    try {
      const response = await HttpService.delete(`/${this.pinnedEntity}/${profileId}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async payForPhoto(photoId: string): Promise<void> {
    try {
      const response = await HttpService.post(`/transactions/chat-photo-open`, { photoId });
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async payForSticker(targetProfileId: string, stickerId: string): Promise<void> {
    try {
      const response = await HttpService.post(`/transactions/sticker-send`, { targetProfileId, stickerId });
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async payForVideo(videoId: string): Promise<void> {
    try {
      const response = await HttpService.post(`/transactions/chat-video-open`, { videoId });
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async payForDialogPhoto(
    dialogPhotoId: string
  ): Promise<{ large: string; origin: string; photoId: string; profileId: string; small: string }> {
    try {
      const response = await HttpService.post(`/transactions/dialog-photo-open`, { dialogPhotoId });
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async payForDialogVideo(
    dialogVideoId: string
  ): Promise<{ large: string; origin: string; videoId: string; profileId: string; small: string }> {
    try {
      const response = await HttpService.post(`/transactions/dialog-video-open`, { dialogVideoId });
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getDialogCounters(): Promise<CountersShape> {
    try {
      const token = StorageService.getToken();
      if (!token) {
        return;
      }
      const response = await HttpService.get(`/counter/dialogs`);

      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async chatPhotoSend(targetProfileId: string): Promise<void> {
    try {
      const response = await HttpService.post(`/transactions/chat-photo-send`, { targetProfileId });
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.message : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async dialogPhotoSend(receiverId: string, photoId: string): Promise<void> {
    try {
      const response = await HttpService.post(`/dialogs/photo`, { receiverId, photoId });
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.message : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async chatVideoSend(targetProfileId: string): Promise<void> {
    try {
      const response = await HttpService.post(`/transactions/chat-video-send`, { targetProfileId });
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.message : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async dialogVideoSend(receiverId: string, videoId: string, origin: string, thumbnail: string): Promise<void> {
    try {
      const response = await HttpService.post(`/dialogs/video`, { receiverId, videoId, origin, thumbnail });
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.message : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
