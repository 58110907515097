import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  stickersRecommendations: {
    maxHeight: '240px',
    overflowY: 'auto',
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
    paddingTop: "2px",
    paddingBottom: "10px",
  },
  stickersCategories: {
    position: "sticky",
    top: "0",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    overflowX: "auto",
    width: "100%",
    paddingTop: "8px",
    paddingBottom: "8px",
    borderBottom: "1px solid #E7E6E6",
  },
  stickersPacks: {
    maxHeight: "280px",
    overflowY: "auto",
  },
  stickerPacksTitle: {
    fontFamily: 'Readex Pro',
    color: "#7D7D7F",
    fontSize: "12px",
    lineHeight: "24px",
    fontWeight: "500",
  }
}));
