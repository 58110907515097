import React, { memo } from "react";
import { DialogMessageProps } from "@/components/dialog-container/components/DialogMessage/DialogMessage.props";
import { STICKER_RESERVED_TEXT, STICKER_URL_REGEX } from "@/helpers/constants";
import StickersItemButton, { StickersUploadButtonType } from "@/components/base/StickersItemButton";

export const DialogMessage = memo<DialogMessageProps>(({
                                                         text,
                                                       }) => {

  const isSticker = STICKER_URL_REGEX.test(text);

  const stickerItem = {
    id: '',
    unicode: text,
    image: isSticker ? text.replace(`${STICKER_RESERVED_TEXT} + `, "") : '',
  }

  if (isSticker) {
    return <div>
      <StickersItemButton item={stickerItem} type={StickersUploadButtonType.viewOnly} />
    </div>
  }

  return <>{text}</>
})
