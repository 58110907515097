import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from '@sentry/react';

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./index.css";

Sentry.init({
    dsn: 'https://ee041ae4a091d73dfaff98a758b3b9b8@o4508445516824576.ingest.de.sentry.io/4508445520887888',
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV
});

ReactDOM.render(
  <React.StrictMode>
      <Sentry.ErrorBoundary>
          <App />
      </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
