import { DialogInviteMessage, UserDialog, } from '@/types/user-profile.types';
import { AnyAction } from 'redux';
import {
  CLEAR_DIALOG_INVITES_DATA,
  GET_DIALOG_INVITES_SUCCESS,
  SET_DIALOG_INVITE_ACTIVE_DATA,
  SET_SKIPPED_INVITES_DATA,
  SET_UPDATED_DIALOG_INVITES_DATA,
} from "@/app/dialogInvites/dialogInvites.types";

export interface DialogInvitesState {
  dialogInvites: UserDialog;
  visibleDialogInvites: DialogInviteMessage[];
  skippedInvites: DialogInviteMessage[];
}

const initialState: DialogInvitesState = {
  dialogInvites: {
    prevPage: false,
    nextPage: false,
    data: [],
  },
  visibleDialogInvites: [],
  skippedInvites: [],
};

const dialogInvites = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case GET_DIALOG_INVITES_SUCCESS: {
      return {
        ...state,
        dialogInvites: { ...action.payload },
      };
    }
    case SET_DIALOG_INVITE_ACTIVE_DATA: {
      const { visibleDialogInvites } = action.payload

      return {
        ...state,
        visibleDialogInvites,
      };
    }
    case SET_UPDATED_DIALOG_INVITES_DATA:
    case SET_SKIPPED_INVITES_DATA: {
      const { skippedInvites, visibleDialogInvites } = action.payload

      return {
        ...state,
        visibleDialogInvites,
        skippedInvites
      };
    }
    case CLEAR_DIALOG_INVITES_DATA: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default dialogInvites;
