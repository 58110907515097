import { makeStyles } from '@mui/styles';
import React from 'react';
import BaseButton from "@/components/base/button.component";
import { ReactComponent as AddStickerIcon } from '@/assets/icons/addSticker.svg';
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  button: {
    background: 'none!important',
    paddingLeft: '0!important',
    paddingRight: '0!important',
    boxShadow: 'none!important',
    minWidth: '34px !important',

    '& span': {
      marginRight: '0!important',
      marginLeft: '0!important',
    },
  },
}));

type StickersUploadButtonProps = {
  inputText: string;
  onClick: () => void;
  className?: string;
};

const StickersUploadButton = ({
                                inputText,
                                onClick,
                                className,
                              }: StickersUploadButtonProps) => {
  const classes = useStyles();

  const isVisible = inputText.trim().length <= 0

  return isVisible ? <BaseButton
    text=''
    inverted
    className={clsx(className, classes.button)}
    onClick={onClick}
    Icon={<AddStickerIcon />}
  /> : null
};

export default StickersUploadButton;
