import { DialogInviteMessage, DialogMessage, UserDialog } from "@/types/user-profile.types";
import {
  CLEAR_DIALOG_INVITES_DATA,
  GENERATE_NEW_DIALOG_INVITE_ACTIVE_DATA,
  GET_DIALOG_INVITES_REQUEST,
  GET_DIALOG_INVITES_SUCCESS,
  HIDE_DIALOG_INVITE_ACTIVE_ACTION,
  READ_USER_ACTIVE_DIALOG_INVITES,
  SET_DIALOG_INVITE_ACTIVE_DATA,
  SET_SKIPPED_INVITES_DATA,
  SET_UPDATED_DIALOG_INVITES_DATA,
  SKIP_DIALOG_INVITE_ACTIVE_ACTION,
  UPDATE_DIALOG_INVITE_SOCKET_DATA
} from "@/app/dialogInvites/dialogInvites.types";

export const getDialogInvitesRequest = (payload?: { offset?: number }) => ({
  type: GET_DIALOG_INVITES_REQUEST,
  payload
});

export const getDialogInvitesSuccess = (payload: UserDialog) => ({
  type: GET_DIALOG_INVITES_SUCCESS,
  payload,
});

export const setDialogInviteActiveData = (payload: {
  visibleDialogInvites: DialogInviteMessage[],
}) => ({
  type: SET_DIALOG_INVITE_ACTIVE_DATA,
  payload,
});

export const updateDialogInviteSocketData = (payload: {
  message: DialogMessage,
}) => ({
  type: UPDATE_DIALOG_INVITE_SOCKET_DATA,
  payload,
});

export const generateNewDialogInviteActiveDataAction = () => ({
  type: GENERATE_NEW_DIALOG_INVITE_ACTIVE_DATA,
});

export const readUserActiveDialogInvitesAction = (payload: {
  id: string,
}) => ({
  type: READ_USER_ACTIVE_DIALOG_INVITES,
  payload,
});

export const skipDialogInviteActiveAction = (payload: {
  dialogInvite: DialogInviteMessage,
}) => ({
  type: SKIP_DIALOG_INVITE_ACTIVE_ACTION,
  payload,
});

export const hideDialogInviteActiveAction = (payload: {
  dialogInvite: DialogInviteMessage,
}) => ({
  type: HIDE_DIALOG_INVITE_ACTIVE_ACTION,
  payload,
});

export const setSkippedInvitesData = (payload: {
  skippedInvites: DialogInviteMessage[],
  visibleDialogInvites: DialogInviteMessage[],
}) => ({
  type: SET_SKIPPED_INVITES_DATA,
  payload,
});

export const setUpdatedDialogInvitesData = (payload: {
  skippedInvites: DialogInviteMessage[],
  visibleDialogInvites: DialogInviteMessage[],
}) => ({
  type: SET_UPDATED_DIALOG_INVITES_DATA,
  payload,
});

export const clearDialogInvitesData = () => ({
  type: CLEAR_DIALOG_INVITES_DATA,
});
