import { useCallback, useEffect, useMemo, useState } from 'react';
import BaseCard from '@/components/base/base-card.component';
import { useAppBreakpoints, useContainerWidth } from '@/hooks';
import { CircularProgress, Container, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BaseButton from '@/components/base/button.component';
import { toggleSnackbarOpen } from '@/app/ui/ui.actions';
import { useAppDispatch } from '@/app/hooks';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import UnfoldMoreOutlined from '@mui/icons-material/UnfoldMoreOutlined';
import SelectField from '@/components/base/select-field.component';
import OrdersService, { CreditsAliasEnum, CreditsAliasReverse, TypeIcons } from '@/services/orders.service';
import { Formik } from 'formik';
import BaseAutocomplete from '@/components/base/base-autocomplete';
import { UserProfile } from '@/types/user-profile.types';
import useDebounce from '@/hooks/useDebounce';
import ProfilesService from '@/services/profiles.service';
import { formatDateTimeUTC } from '@/utils/formatDate';
import { ExpandableTableRow } from '../../components/base/ExpndableRow';
import useStaffCheck from '@/hooks/useStaffCheck';

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    zIndex: 1000,
    marginTop: 40,
    [theme.breakpoints.down('xl')]: {
      marginTop: 24,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 14,
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 27,

    [theme.breakpoints.down('md')]: {
      marginBottom: 32,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginBottom: 20,
      alignItems: 'flex-start',
    },
  },

  headerButtonWrapper: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },

  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  loadMoreWrapper: {
    alignSelf: 'center',
    minWidth: '200px !important',
  },

  loadMoreBtn: {
    maxWidth: 200,
    minWidth: 200,
  },

  loadButtonSection: {
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    marginTop: '28px',
  },

  tableHeader: {
    backgroundColor: '#503EB6',
    '& th': {
      fontSize: '16px',
      lineHeight: '28px',
      borderBottom: 'none',
      color: '#ffffff',
      padding: '20px 16px',
      paddingLeft: '0',

      [theme.breakpoints.down('sm')]: {
        padding: '14px 10px',
      },

      '&:first-child': {
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        paddingLeft: '20px',
      },
      '&:last-child': {
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',

        '& > $headerCell': {
          borderRight: 'none',
          paddingRight: '0',
        },
      },
    },
  },

  headerCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRight: '1px solid white',
    paddingRight: '10px',
  },

  infoHeaderCell: {
    inlineSize: 'max-content',
  },

  tableBody: {
    '& td': {
      fontSize: '16px',
      lineHeight: '32px',
      padding: '20px 16px',
      paddingLeft: '0',

      [theme.breakpoints.down('sm')]: {
        padding: '20px 10px',
      },

      '&:first-child': {
        paddingLeft: '20px',
      },
    },
  },

  infoName: {
    lineHeight: '18px',
  },

  infoId: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
  },

  selectField: {
    minWidth: 246,
    [theme.breakpoints.down('md')]: {
      minWidth: 125,
      '& .MuiFilledInput-input': {
        maxWidth: '75px !important',
      },
    },
  },

  searchHeader: {
    minWidth: 246,
    padding: '0 10px',
    [theme.breakpoints.down('sm')]: {
      minWidth: 125,
      padding: '0 5px',
      '& input': {
        width: '97px !important',
      },
    },
  },

  increasePadding: {
    paddingLeft: 5,
  },

  noOrders: {
    margin: '0 auto',
    border: '2px dashed #503EB6',
    padding: '10px',
    width: '150px',
  },
}));

const typeList = [
  { id: 0, name: CreditsAliasEnum.all },
  { id: 1, name: CreditsAliasEnum.creditsMessageNew },
  { id: 2, name: CreditsAliasEnum.creditsMessagePhotoOpen },
  { id: 3, name: CreditsAliasEnum.creditsMessageVideoOpen },
  { id: 4, name: CreditsAliasEnum.creditsChatTextMin },
  { id: 5, name: CreditsAliasEnum.creditsChatVideoMin },
  { id: 6, name: CreditsAliasEnum.creditsChatPhotoOpen },
  { id: 7, name: CreditsAliasEnum.creditsChatVideoOpen },
  { id: 8, name: CreditsAliasEnum.creditsChatPhotoSend },
  { id: 9, name: CreditsAliasEnum.creditsChatVideoSend },
  { id: 10, name: CreditsAliasEnum.freeCreditsAdmin },
  { id: 11, name: CreditsAliasEnum.creditsMessageSend },
  { id: 12, name: CreditsAliasEnum.creditsRealInfo },
  { id: 13, name: CreditsAliasEnum.creditsMessageOpen },
  { id: 14, name: CreditsAliasEnum.virtualGiftsSend },
  { id: 15, name: CreditsAliasEnum.virtualGiftsReceived },
  { id: 16, name: CreditsAliasEnum.creditsDialogPhotoSend },
  { id: 17, name: CreditsAliasEnum.creditsDialogVideoSend },
  { id: 18, name: CreditsAliasEnum.creditsDialogTextSend },
  { id: 19, name: CreditsAliasEnum.creditsDialogPhotoOpen },
  { id: 20, name: CreditsAliasEnum.creditsDialogVideoOpen },
  { id: 21, name: CreditsAliasEnum.creditsProfilesBlacklistAdd },
  { id: 22, name: CreditsAliasEnum.creditsStickerSend },
];

const columns: { id: string; label: string }[] = [
  { id: 'id', label: 'S/N' },
  { id: 'info', label: 'Spent On' },
  { id: 'action', label: 'Type' },
  { id: 'credits', label: 'Spent/Pay' },
  { id: 'createdAt', label: 'Date' },
];

const mobileColumns: { id: string; label: string }[] = [
  { id: 'action', label: 'Type' },
  { id: 'credits', label: 'Spent/Pay' },
  { id: 'arrow', label: '' },
];

const OrderHistory = () => {
  useStaffCheck();
  const stylesOverrides = useMemo(
    () => ({
      contentWrapper: {
        padding: 0,
        maxWidth: 968,
      },
    }),
    []
  );

  const classes = useStyles();
  const width = useContainerWidth();
  const dispatch = useAppDispatch();
  const { xs } = useAppBreakpoints();

  const [orderItems, setOrderItems] = useState([]);
  const [isPaginationOver, setIsPaginationOver] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginationOffset, setPaginationOffset] = useState<number>(0);

  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>(null);

  const [results, setResults] = useState<UserProfile[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [chosenProfile, setChosenProfile] = useState<any>(null);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true);

      ProfilesService.getAllProfilesByPageId(debouncedSearchTerm).then((results) => {
        setIsSearching(false);
        setResults(results.data);
      });
    } else {
      setResults([]);
    }
  }, [debouncedSearchTerm]);

  const [sorting, setSorting] = useState<CreditsAliasEnum>(CreditsAliasEnum.all);

  const getControlledSortValue = useMemo(() => {
    switch (sorting) {
      case CreditsAliasEnum.all:
        return typeList[0];
      case CreditsAliasEnum.creditsMessageNew:
        return typeList[1];
      case CreditsAliasEnum.creditsMessagePhotoOpen:
        return typeList[2];
      case CreditsAliasEnum.creditsMessageVideoOpen:
        return typeList[3];
      case CreditsAliasEnum.creditsChatTextMin:
        return typeList[4];
      case CreditsAliasEnum.creditsChatVideoMin:
        return typeList[5];
      case CreditsAliasEnum.creditsChatPhotoOpen:
        return typeList[6];
      case CreditsAliasEnum.creditsChatVideoOpen:
        return typeList[7];
      case CreditsAliasEnum.creditsChatPhotoSend:
        return typeList[8];
      case CreditsAliasEnum.creditsChatVideoSend:
        return typeList[9];
      case CreditsAliasEnum.freeCreditsAdmin:
        return typeList[10];
      case CreditsAliasEnum.creditsMessageSend:
        return typeList[11];
      case CreditsAliasEnum.creditsRealInfo:
        return typeList[12];
      case CreditsAliasEnum.creditsMessageOpen:
        return typeList[13];
      case CreditsAliasEnum.virtualGiftsSend:
        return typeList[14];
      case CreditsAliasEnum.virtualGiftsReceived:
        return typeList[15];
      case CreditsAliasEnum.creditsDialogPhotoSend:
        return typeList[16];
      case CreditsAliasEnum.creditsDialogVideoSend:
        return typeList[17];
      case CreditsAliasEnum.creditsDialogTextSend:
        return typeList[18];
      case CreditsAliasEnum.creditsDialogPhotoOpen:
        return typeList[19];
      case CreditsAliasEnum.creditsDialogVideoOpen:
        return typeList[20];
      case CreditsAliasEnum.creditsProfilesBlacklistAdd:
        return typeList[21];
      case CreditsAliasEnum.creditsMessageVideoSend:
        return typeList[22];
      case CreditsAliasEnum.creditsStickerSend:
        return typeList[23];
      default:
        return typeList[0];
    }
  }, [sorting]);

  const handleSortBy = (id: string) => {
    setSortBy(id);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  useEffect(() => {
    setPaginationOffset(0);
    setOrderItems([]);
    setIsPaginationOver(false);
    loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortOrder, chosenProfile, sorting]);

  const loadMore = useCallback(
    async (offset = 0) => {
      setIsLoading(true);

      try {
        const response = await OrdersService.getOrders(
          offset,
          sortBy,
          sortOrder,
          chosenProfile,
          CreditsAliasReverse[sorting] as CreditsAliasEnum
        );

        if (response.length < 20) {
          setIsPaginationOver(true);
        }

        setOrderItems(!offset ? response : orderItems.concat(response));
      } catch {
        dispatch(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
      }

      setPaginationOffset(offset);

      setIsLoading(false);
    },
    [chosenProfile, dispatch, orderItems, sortBy, sortOrder, sorting]
  );

  const getLoadButton = useMemo(() => {
    if (isPaginationOver || !orderItems.length) return null;
    if (isLoading) return <CircularProgress color='secondary' style={{ margin: '0 auto' }} />;

    return (
      <BaseButton
        text='Load More (20)'
        color='primary'
        wrapperClass={classes.loadMoreWrapper}
        className={classes.loadMoreBtn}
        onClick={() => loadMore(paginationOffset + 20)}
      />
    );
  }, [
    classes.loadMoreBtn,
    classes.loadMoreWrapper,
    isLoading,
    isPaginationOver,
    loadMore,
    orderItems.length,
    paginationOffset,
  ]);

  const getTypeCell = useCallback((cell: any) => {
    const Icon = TypeIcons[cell];

    return (
      <div>
        {<Icon color='radioSelected' fontSize='small' />}&nbsp; {CreditsAliasEnum[cell]}
      </div>
    );
  }, []);

  const formatedCreatedAtDate = useCallback((createdAt: Date) => {
    const dateTime = formatDateTimeUTC(createdAt);
    return dateTime;
  }, []);

  const getTableCell = useCallback(
    (row: any, column: any) => {
      if (column.id === 'info') {
        return (
          <div>
            <div className={classes.infoName}>{row[column.id].name}</div>
            <div className={classes.infoId}>ID: {row[column.id].id}</div>
          </div>
        );
      }

      if (column.id === 'createdAt') {
        return (
          <>
            <div>{formatedCreatedAtDate(row[column.id]).time}</div>
            <div>{formatedCreatedAtDate(row[column.id]).date}</div>
          </>
        );
      }

      if (column.id === 'action') {
        return getTypeCell(row[column.id]);
      }

      return row[column.id];
    },
    [classes.infoId, classes.infoName, formatedCreatedAtDate, getTypeCell]
  );

  const isAnyOrderItems = useMemo(() => !!orderItems?.length, [orderItems]);

  return (
    <Container maxWidth={width} className={classes.contentWrapper} style={stylesOverrides.contentWrapper}>
      <BaseCard wrapperOverrideStyle={{ margin: 0, paddingBottom: 10 }}>
        <div className={classes.cardWrapper}>
          <header className={classes.header}>
            <Typography variant='h2'>Orders</Typography>

            {isAnyOrderItems ? (
              <Formik
                initialValues={{
                  sort: '',
                }}
                onSubmit={() => {}}
              >
                {({ setFieldValue }) => (
                  <div className={classes.headerButtonWrapper}>
                    <div className={classes.searchHeader}>
                      <BaseAutocomplete
                        setOptions={setResults}
                        name='receiverId'
                        placeholder='ID:'
                        onChange={(e, val) => {
                          setFieldValue('receiverId', val);
                          if (val?.id) {
                            setChosenProfile(val.id);
                          }
                        }}
                        onInput={(e: any) => {
                          if (e.target.validity.valid) {
                            setSearchTerm(e.currentTarget.value);

                            if (!e.currentTarget.value) {
                              setChosenProfile(null);
                            }
                          }
                        }}
                        options={results}
                        loading={isSearching}
                      />
                    </div>

                    <SelectField
                      placeholder='Type'
                      list={typeList}
                      label='sort'
                      className={classes.selectField}
                      onChnage={(value: any) => setSorting(value)}
                      controlledValue={getControlledSortValue}
                    />
                  </div>
                )}
              </Formik>
            ) : null}
          </header>

          {isAnyOrderItems ? (
            xs ? (
              // mobile table
              <TableContainer>
                <Table>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      {mobileColumns.map((column, index) => (
                        <TableCell key={column.id} onClick={() => (index === 2 ? () => {} : handleSortBy(column.id))}>
                          {index === 2 ? (
                            <></>
                          ) : (
                            <div
                              className={`${classes.headerCell} ${
                                column.id === 'info' ? `${classes.infoHeaderCell}` : ''
                              }`}
                            >
                              {column.label}
                              <UnfoldMoreOutlined />
                            </div>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {orderItems.map((row) => (
                      <ExpandableTableRow
                        key={row.name}
                        expandComponent={{
                          'S/N': row.id,
                          'Spent on': `${row.info.name}, ID: ${row.info.id}`,
                          Date: `${formatedCreatedAtDate(row.createdAt).time} ${
                            formatedCreatedAtDate(row.createdAt).date
                          }`,
                        }}
                      >
                        {mobileColumns.flatMap((column, index) =>
                          index === 2 ? [] : <TableCell key={column.id}>{getTableCell(row, column)}</TableCell>
                        )}
                      </ExpandableTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <TableContainer>
                <Table>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id} onClick={() => handleSortBy(column.id)}>
                          <div
                            className={`${classes.headerCell} ${
                              column.id === 'info' ? `${classes.infoHeaderCell}` : ''
                            }`}
                          >
                            {column.label}
                            <UnfoldMoreOutlined />
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {orderItems.map((row) => (
                      <TableRow key={row.id}>
                        {columns.map((column) => (
                          <TableCell key={column.id}>{getTableCell(row, column)}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          ) : (
            <div className={classes.noOrders}>There are no orders</div>
          )}

          <div className={classes.loadButtonSection}>{getLoadButton}</div>
        </div>
      </BaseCard>
    </Container>
  );
};

export default OrderHistory;
