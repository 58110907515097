import React, { useCallback, useEffect, useState } from 'react';
import { useStyles } from "@/components/base/StickersContainer/StickerContainer.styles";
import { StickerItem } from "@/types/stickers.types";
import StickersService from "@/services/stickers.service";
import StickersItemButton, { StickersUploadButtonType } from "@/components/base/StickersItemButton";
import { StickersContainerRecommendationsProps } from "@/components/base/StickersContainer/StickerContainer.props";


const StickersContainerRecommendations = ({
                                            text,
                                            onClick,
                                          }: StickersContainerRecommendationsProps) => {
  const classes = useStyles();
  const [stickers, setStickers] = useState<StickerItem[]>([])


  const getStickers = useCallback(async () => {
    const stickersData = await StickersService.getStickers(text);
    setStickers(stickersData.data.length === 0 ? [] : stickersData.data);
  }, [text])

  useEffect(() => {
    getStickers()
  }, [getStickers]);

  return stickers.length > 0 ? <div className={classes.stickersRecommendations}>
    {
      stickers.map((sticker) => <StickersItemButton item={sticker} onClick={onClick} key={sticker.id}
                                                    type={StickersUploadButtonType.base} />)
    }
  </div> : null;
};

export default StickersContainerRecommendations;
