import React, {useCallback, useEffect, useState, useRef} from 'react';

import Grid from "@mui/material/Grid";
import {useStyles} from "@/components/base/StickersContainer/StickerContainer.styles";
import {StickerPack} from "@/types/stickers.types";
import StickersService from "@/services/stickers.service";
import StickersItemButton, {StickersUploadButtonType} from "@/components/base/StickersItemButton";
import {
    StickersContainerPacksProps,
} from "@/components/base/StickersContainer/StickerContainer.props";

const StickerContainerPacks = ({
   onClick,
}: StickersContainerPacksProps) => {
    const classes = useStyles();
    const [stickerPacks, setStickerPacks] = useState<StickerPack[]>([])
    const stickersCategoryRefs = useRef(new Map<string, HTMLDivElement>());

    const getStickerPacks = useCallback(async () => {
        const stickersData = await StickersService.getStickerPacks(0, 200);
        const stickers = stickersData.data.length === 0 ? [] : stickersData.data;

        stickers.sort((a, b) => a.stickerPackId.localeCompare(b.stickerPackId))

        const stickerPacks = stickers.reduce((packs, sticker) => {
            if (!packs.some(pack => pack.id === sticker.stickerPackId)) {
                packs.push({ id: sticker.stickerPackId, name: sticker.stickerPackName, stickers: [] });
            }

            const pack = packs.find(pack => pack.id === sticker.stickerPackId);
            pack.stickers.push(sticker);
            return packs;
        }, []);

        setStickerPacks(stickerPacks);
    }, [])

    useEffect(() => {
        getStickerPacks();
    }, [getStickerPacks]);

    const handleScrollBottom = (e) => {
        const target = e.target;
        if ((target.scrollHeight - target.scrollTop + 0.5) === target.clientHeight) {
            // function body will be updated with some apu changes
        }
    }

    const scrollToCategory = (category: string) => {
        const categoryElement = stickersCategoryRefs.current.get(category);
        if (categoryElement) {
            categoryElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    if (!stickerPacks.length) {
        return null;
    }

    return (
        <>
            <div className={classes.stickersCategories}>
                {stickerPacks.map((pack) => (
                    <div key={`pack-${pack.id}`}>
                        <StickersItemButton
                            key={pack.id}
                            item={pack.stickers[0]}
                            onClick={() => scrollToCategory(pack.id)}
                            type={StickersUploadButtonType.extraSmall}
                        />
                    </div>
                ))}
            </div>
            <div className={classes.stickersPacks} onScroll={handleScrollBottom}>
                {stickerPacks.map((pack) => (
                    <div key={pack.id} ref={(el) => el && stickersCategoryRefs.current.set(pack.id, el)}>
                        <h5 className={classes.stickerPacksTitle}>{pack.name}</h5>
                        <Grid
                            container
                            spacing={2}
                        >
                            {pack?.stickers?.map((sticker) => (
                                <Grid
                                    key={sticker.id}
                                    item
                                    xs={3}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <StickersItemButton
                                        item={sticker}
                                        onClick={onClick}
                                        key={sticker.id}
                                        type={StickersUploadButtonType.base}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                ))}
            </div>
        </>
    );
};

export default StickerContainerPacks;
