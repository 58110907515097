import { Container } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useAppSelector } from '@/app/hooks';
import { ISettings } from '@/types/user-profile.types';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 'calc(100% - 56px)',
    padding: '28px',
    marginTop: '-12px',
    zIndex: '90',
    background: '#FFFFFF',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  headTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '26px',
    lineHeight: '26px',
    paddingBottom: '33px',
    width: '100%',
    textAlign: 'center',
  },
  paragraphFirst: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    paddingBottom: '10px',
  },
  paragraph: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    paddingBottom: '10px',
    paddingLeft: '40px',
  },
  headline: {
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '44px',
    marginBlockStart: 0,
    marginBlockEnd: 0,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: '#503EB6',
    paddingInlineStart: '20px',
    '& > ::marker': {
      color: '#EA4160',
    },
  },
  breakline: {
    background: '#E7E6E6',
    height: '1px',
    width: '100%',
    marginBottom: '10px',
  },
  title: {
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '24px',

    color: '#000000',
    paddingBottom: '8px',
    display: 'flex',
    '& svg': {
      paddingRight: '10px',
    },
  },
  questionBody: {
    fontSize: '14px',
    lineHeight: '20px',

    color: '#808080',
    paddingBottom: '16px',
    paddingLeft: '28px',
  },
  styledOl: {
    paddingInlineStart: '14px',
    '& > ::marker': {
      color: '#503EB6',
    },
  },
  link: {
    display: 'inline',
    color: '#EA4160',
  },
}));

const PriceList = ({ className }: { className?: string }) => {
  const classes = useStyles();

  const {
    creditsMessageOpen,
    creditsMessageSend,
    creditsMessagePhotoOpen,
    creditsMessageVideoSend,
    creditsMessageVideoOpen,
    creditsChatPhotoSend,
    creditsChatPhotoOpen,
    creditsChatTextMin,
    creditsChatVideoMin,
    creditsChatVideoSend,
    creditsChatVideoOpen,
    creditsDialogTextSend,
    creditsDialogPhotoSend,
    creditsDialogPhotoOpen,
    creditsDialogVideoSend,
    creditsDialogVideoOpen,
    creditsProfilesBlacklistAdd,
    creditsStickerSend,
  } = useAppSelector<ISettings>((state) => state.users.settings);

  return (
    <Container className={className}>
      <div className={classes.headTitle}>Price List</div>
      <strong className={classes.title}>Letters:</strong>
      <ul className={classes.styledOl}>
        <li>
          Writing letters to users - {creditsMessageSend} credits. <br />
          <em>
            Note: The first letter you write to a new user or the first letter you receive from a new user in a dialogue
            is free of charge! Any subsequent actions, such as responses or reading responses to these initial letters,
            are subject to the usual credit cost. Sending photos in letters is also free of charge.
          </em>
        </li>
        <li>Reading letters from users - {creditsMessageOpen} credits.</li>
        <li>Open private photos in letters (beyond the first photo) - {creditsMessagePhotoOpen} credits.</li>
        <li>Sending private video in letters - {creditsMessageVideoSend} credits.</li>
        <li>Open private video in letters - {creditsMessageVideoOpen} credits.</li>
      </ul>
      <strong className={classes.title}>Gifts:</strong>
      <ul className={classes.styledOl}>
        <li>Sending virtual gifts to users - gift price is indicated in credits for each gift.</li>
      </ul>
      <strong className={classes.title}>Chat:</strong>
      <ul className={classes.styledOl}>
        <li>Chatting in text chat - {creditsChatTextMin} credits per minute.</li>
        <li>Chatting in video chat - {creditsChatVideoMin} credits per minute.</li>
        <li>Sending private photos in chat - {creditsChatPhotoSend} credits.</li>
        <li>Open private photos in chat - {creditsChatPhotoOpen} credit.</li>
        <li>Sending private video in chat - {creditsChatVideoSend} credits.</li>
        <li>Open private video in chat - {creditsChatVideoOpen} credits.</li>
      </ul>
      <strong className={classes.title}>Dialogs:</strong>
      <ul className={classes.styledOl}>
        <li>Sending a message in dialogs - {creditsDialogTextSend} credits.</li>
        <li>Sending private photos in dialogs - {creditsDialogPhotoSend} credits.</li>
        <li>Open private photos in dialogs - {creditsDialogPhotoOpen} credit.</li>
        <li>Sending private video in dialogs - {creditsDialogVideoSend} credits.</li>
        <li>Open private video in dialogs - {creditsDialogVideoOpen} credits.</li>
        <li>Sending a sticker in dialogs - {creditsStickerSend} credits.</li>
      </ul>
      <strong className={classes.title}>Black List:</strong>
      <ul className={classes.styledOl}>
        <li>Add user to blacklist - {creditsProfilesBlacklistAdd} credits.</li>
      </ul>
    </Container>
  );
};

export default PriceList;
