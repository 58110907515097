import {
  DialogMessage,
  IBlackList,
  ISettings,
  IVideoInviteProfile,
  UserDialog,
  UserProfile,
  UserProfileState,
} from '@/types/user-profile.types';
import { AnyAction } from 'redux';
import {
  IInappMessagePayload,
  IQuestions,
  IUserPhotosData,
  UserPhotosDTO,
  defaultUserPhotosData,
  IUserVideosData,
  UserVideosDTO,
  defaultUserVideosData,
} from './users.dto';
import {
  CLEAR_USER_REQUEST,
  CLEAR_USER_SUCCESS,
  DELETE_USER_PHOTOS_FAILURE,
  DELETE_USER_PHOTOS_REQUEST,
  DELETE_USER_PHOTOS_SUCCESS,
  DELETE_USER_VIDEOS_FAILURE,
  DELETE_USER_VIDEOS_REQUEST,
  DELETE_USER_VIDEOS_SUCCESS,
  GET_AVAILABLE_USERS_FAILURE,
  GET_AVAILABLE_USERS_REQUEST,
  GET_AVAILABLE_USERS_SUCCESS,
  GET_OWNER_PROFILE_FAILURE,
  GET_OWNER_PROFILE_REQUEST,
  GET_OWNER_PROFILE_SUCCESS,
  GET_PROFILE_PHOTOS_FAILURE,
  GET_PROFILE_PHOTOS_REQUEST,
  GET_PROFILE_PHOTOS_SUCCESS,
  GET_PROFILE_VIDEOS_FAILURE,
  GET_PROFILE_VIDEOS_REQUEST,
  GET_PROFILE_VIDEOS_SUCCESS,
  GET_SEARCH_USERS_FAILURE,
  GET_SEARCH_USERS_REQUEST,
  GET_SEARCH_USERS_SUCCESS,
  GET_USER_PHOTOS_FAILURE,
  GET_USER_PHOTOS_REQUEST,
  GET_USER_PHOTOS_SUCCESS,
  GET_USER_VIDEOS_FAILURE,
  GET_USER_VIDEOS_REQUEST,
  GET_USER_VIDEOS_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  POST_USER_PHOTOS_FAILURE,
  POST_USER_PHOTOS_REQUEST,
  POST_USER_PHOTOS_SUCCESS,
  POST_USER_VIDEOS_FAILURE,
  POST_USER_VIDEOS_REQUEST,
  POST_USER_VIDEOS_SUCCESS,
  RECEIVE_MESSAGE_TO_CHAT_SUCCESS,
  TRANSLATE_MESSAGE_TO_CHAT_SUCCESS,
  SEND_MESSAGE_TO_CHAT_SUCCESS,
  UPDATE_CURRENT_USER_PROFILE_FAILURE,
  UPDATE_CURRENT_USER_PROFILE_REQUEST,
  UPDATE_CURRENT_USER_PROFILE_SUCCESS,
  SET_BOOKMARKS_REQUEST,
  SET_BOOKMARKS_SUCCESS,
  SET_BOOKMARKS_FAILURE,
  GET_BOOKMARKS_REQUEST,
  GET_BOOKMARKS_SUCCESS,
  GET_BOOKMARKS_FAILURE,
  DELETE_BOOKMARKS_REQUEST,
  DELETE_BOOKMARKS_SUCCESS,
  DELETE_BOOKMARKS_FAILURE,
  SET_BLOCKED_BY_ME_REQUEST,
  SET_BLOCKED_BY_ME_SUCCESS,
  SET_BLOCKED_BY_ME_FAILURE,
  DELETE_BLOCKED_BY_ME_REQUEST,
  DELETE_BLOCKED_BY_ME_SUCCESS,
  DELETE_BLOCKED_BY_ME_FAILURE,
  GET_BLOCKED_USERS_REQUEST,
  GET_BLOCKED_USERS_SUCCESS,
  GET_BLOCKED_USERS_FAILURE,
  SET_UPDATE_AVAILABLE_USERS,
  SET_DISCONNECTED_AVAILABLE_USERS,
  REMOVE_INVITE,
  UPDATE_BALANCE_FAILED,
  UPDATE_BALANCE_SUCCESS,
  UPDATE_BALANCE_REQUEST,
  CHARGE_BALANCE_FAILED,
  CHARGE_BALANCE_SUCCESS,
  CHARGE_BALANCE_REQUEST,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILED,
  GET_EMAIL_EVENTS_BLACKLIST_REQUEST,
  GET_EMAIL_EVENTS_BLACKLIST_SUCCESS,
  GET_EMAIL_EVENTS_BLACKLIST_FAILED,
  DISCONNECT_CHAT,
  USER_BALANCE_CHARGED_SUCCESS,
  SET_VIDEO_CALL,
  SET_USER_HEADER_SEARCH,
  SEND_PHOTO_MESSAGE_TO_CHAT_SUCCESS,
  RECEIVE_PHOTO_MESSAGE_TO_CHAT_SUCCESS,
  REVEAL_PHOTO_CHAT_SUCCESS,
  SEND_VIDEO_MESSAGE_TO_CHAT_SUCCESS,
  RECEIVE_VIDEO_MESSAGE_TO_CHAT_SUCCESS,
  REVEAL_VIDEO_CHAT_SUCCESS,
  UPDATE_CURRENT_USER_AVATAR_REQUEST,
  CLEAR_AVAILABLE_USERS_SUCCESS,
  READ_MESSAGE,
  REMOVE_VIDEO_INVITE,
  ADD_VIDEO_INVITE,
  GET_USERS_DIALOGS_REQUEST,
  GET_USERS_DIALOGS_SUCCESS,
  GET_USERS_DIALOGS_FAILURE,
  ADD_USERS_DIALOGS_SUCCESS,
  GET_DIALOG_COUNTERS_SUCCESS,
  SOCKET_UPDATE_DIALOG_COUNT,
  READ_USERS_DIALOGS_REQUEST,
  UPDATE_PIN_SUCCESS,
  UPDATE_INAPP_REQUEST,
  UPDATE_INAPP_SUCCESS,
  UPDATE_INAPP_FAILED,
  FILTER_INAPP_REQUEST,
  FILTER_INAPP_SUCCESS,
  FILTER_INAPP_FAILED,
  UPDATE_QUESTIONS_REQUEST,
  UPDATE_QUESTIONS_SUCCESS,
  UPDATE_QUESTIONS_FAILED,
  FILTER_QUESTIONS_REQUEST,
  FILTER_QUESTIONS_SUCCESS,
  FILTER_QUESTIONS_FAILED,
  GET_ACQUIRING_REQUEST,
  GET_ACQUIRING_SUCCESS,
  GET_ACQUIRING_FAILED,
  CLOSE_QUESTIONS_FOR_DAYS_SUCCESS,
  SET_VIDEO_CONVERTED,
  CHANGE_USER_EMAIL,
  CHANGE_USER_EMAIL_SUCCESS,
} from './users.types';
import { UserRolesEnum } from '@/components/chat-container/enums/user-roles.enum';
import { isWithinLastDays, isWithinLastMinutesParams } from '@/services/utils';
import {
  dialogsSortByPinned,
  findIsDialogPinned,
  getDialogIndex,
  getLargestPinNumber
} from "@/app/users/users.helpers";

export const MINUS_CURRENT_TIMER_FOR_CHARGE_VALUE = 61000;

export type UserProfileDTO = {
  id: string;
  email: string | null;
  role: UserRolesEnum;
  iat: number;
  exp: number;
  profile: UserProfile;
};

export enum EmailEventEnum {
  reportDaily = 'reportDaily',
  birthdayLetter = 'birthdayLetter',
  emailFreeCredits = 'emailFreeCredits',
  emailVirtualGift = 'emailVirtualGift',
  emailMessage = 'emailMessage',
  creditsBought = 'creditsBought',
}

export interface EmailEventsBlacklist {
  id: string;
  profileId: string;
  emailEvent: EmailEventEnum;
}

export interface IUserState {
  userIsLoading: boolean;
  currentUser: UserProfileDTO | null;
  searchUsers: { data: UserProfile[]; total: number };
  user: any;
  publicPhotos: IUserPhotosData;
  privatePhotos: IUserPhotosData;
  publicVideos: IUserVideosData;
  privateVideos: IUserVideosData;
  inAppMessages: IInappMessagePayload;
  questions: IQuestions[];
  questionsForget: Date | null;
  photosIsLoading: boolean;
  photosIsUploading: boolean;
  photosIsDeleting: boolean;
  videosIsLoading: boolean;
  videosIsUploading: boolean;
  videosIsDeleting: boolean;
  userAvailableIsLoading: boolean;
  availableUsers: Array<UserProfileState>;
  bookmarks: UserProfile[];
  bookmarksLoading: boolean;
  blockedUsers: IBlackList[];
  blockedUsersLoading: boolean;
  invites: Array<UserProfileState>;
  videoInvites: Array<IVideoInviteProfile>;
  usersWithActiveTextChat: { id: string; date: Date }[];
  videoActive: {
    videoProfileId: string;
    date: null | Date;
  };
  wallet: {
    balance: number;
    isLoading: boolean;
  };
  settings?: ISettings;
  headerUserSearch: string | null;
  userDialogs: UserDialog;
  dialogCounters: {
    unseen: number;
    bookmarksUnseen: number;
  };
  acquiring: string[];
  emailEventsBlackList: EmailEventsBlacklist[];
}

const initialState: IUserState = {
  userIsLoading: false,
  user: {},
  currentUser: null,
  photosIsLoading: false,
  photosIsUploading: false,
  photosIsDeleting: false,
  videosIsLoading: false,
  videosIsUploading: false,
  videosIsDeleting: false,
  publicPhotos: defaultUserPhotosData,
  privatePhotos: defaultUserPhotosData,
  publicVideos: defaultUserVideosData,
  privateVideos: defaultUserVideosData,
  searchUsers: {
    data: [],
    total: 0,
  },
  inAppMessages: {
    prevPage: false,
    nextPage: false,
    data: [],
    isLoading: false,
  },
  questions: [],
  questionsForget: null,
  userAvailableIsLoading: false,
  availableUsers: [],
  invites: [],
  videoInvites: [],
  bookmarks: [],
  bookmarksLoading: false,
  blockedUsers: [],
  blockedUsersLoading: false,
  userDialogs: {
    prevPage: false,
    nextPage: false,
    data: [],
  },
  dialogCounters: {
    unseen: 0,
    bookmarksUnseen: 0,
  },
  usersWithActiveTextChat: [],
  headerUserSearch: null,
  videoActive: {
    videoProfileId: null,
    date: null,
  },
  wallet: {
    balance: 0,
    isLoading: false,
  },
  settings: {
    companyAddress: null,
    companyName: null,
    companyCountry: null,
    companyPhone: null,
    emailForPaypal: null,
    supportEmail: null,
    siteUrl: null,
    siteName: null,
    rnCompany: null,
    creditsMessageOpen: 2,
    creditsMessageSend: 14,
    creditsMessageNew: 8,
    creditsChatPhotoOpen: 8,
    creditsChatPhotoSend: 8,
    creditsChatTextMin: 2,
    creditsChatVideoMin: 6,
    creditsChatVideoOpen: 16,
    creditsChatVideoSend: 16,
    creditsDialogPhotoSend: 5,
    creditsDialogPhotoOpen: 2,
    creditsDialogVideoSend: 5,
    creditsDialogVideoOpen: 2,
    creditsMessagePhotoOpen: 8,
    creditsMessageVideoOpen: 16,
    creditsMessageVideoSend: 25,
    creditsDialogTextSend: 1.25,
    creditsProfilesBlacklistAdd: 2,
    creditsStickerSend: 1,
    creditsRealInfo: 500,
    sysTimeWhileNew: 1296000000,
    paymentsFlexpay: false,
    paymentsStripe: false,
    paymentsStripebg01: false,
    paymentsCcbill: false,
    paymentsCcbillUsd: false,
    paymentsInoviopay: false,
    paymentsInoviopay3ds2: false,
    paymentsNetvalve: false,
    liveChat: false,
  },
  acquiring: [],
  emailEventsBlackList: [],
};

const users = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case GET_OWNER_PROFILE_REQUEST:
    case GET_OWNER_PROFILE_SUCCESS:
      return {
        ...state,
        userIsLoading: false,
      };

    case GET_SEARCH_USERS_REQUEST:
      return {
        ...state,
        userIsLoading: true,
      };

    case GET_SEARCH_USERS_SUCCESS:
      return {
        ...state,
        userIsLoading: false,
        searchUsers: action.users,
      };

    case GET_SEARCH_USERS_FAILURE:
      return {
        ...state,
        userIsLoading: false,
      };

    case UPDATE_CURRENT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userIsLoading: false,
        currentUser: { ...state.currentUser, profile: action.user },
      };

    case GET_OWNER_PROFILE_FAILURE:
    case UPDATE_CURRENT_USER_PROFILE_FAILURE:
      return {
        ...state,
        userIsLoading: false,
      };

    case UPDATE_CURRENT_USER_AVATAR_REQUEST:
    case GET_USER_PROFILE_REQUEST:
    case UPDATE_CURRENT_USER_PROFILE_REQUEST:
      return {
        ...state,
        userIsLoading: true,
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userIsLoading: false,
        currentUser: action.user,
      };
    case GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        userIsLoading: false,
        error: action.error,
      };
    case CLEAR_USER_REQUEST:
      return {
        ...state,
        userIsLoading: false,
      };
    case CLEAR_USER_SUCCESS:
      return {
        ...state,
        userIsLoading: false,
        user: {},
        currentUser: null,
        searchUsers: {
          data: [],
          total: 0,
        },
      };
    case CHANGE_USER_EMAIL_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          email: action.email,
        },
      };
    //photos

    case POST_USER_PHOTOS_REQUEST:
      return {
        ...state,
        photosIsUploading: true,
      };
    case DELETE_USER_PHOTOS_REQUEST:
      return {
        ...state,
        photosIsDeleting: true,
      };

    case POST_USER_PHOTOS_FAILURE:
      return {
        ...state,
        photosIsUploading: false,
        error: action.error,
      };
    case DELETE_USER_PHOTOS_FAILURE:
      return {
        ...state,
        photosIsDeleting: false,
        error: action.error,
      };

    case GET_USER_PHOTOS_REQUEST: {
      return {
        ...state,
        photosIsLoading: true,
        ...(action.offset ? {} : { privatePhotos: { ...defaultUserPhotosData } }),
        ...(action.offset ? {} : { publicPhotos: { ...defaultUserPhotosData } }),
      };
    }

    case GET_USER_PHOTOS_SUCCESS:
      switch (action.viewType) {
        case 'private':
          return {
            ...state,
            photosIsLoading: false,
            privatePhotos: {
              nextPage: action.photos.nextPage,
              prevPage: action.photos.prevPage,
              data: [...state.privatePhotos.data, ...action.photos.data],
            },
          };
        case 'public':
          return {
            ...state,
            photosIsLoading: false,
            publicPhotos: {
              nextPage: action.photos.nextPage,
              prevPage: action.photos.prevPage,
              data: [...state.publicPhotos.data, ...action.photos.data],
            },
          };
        default:
          return {
            ...state,
            photosIsLoading: false,
            privatePhotos: {
              nextPage: true,
              prevPage: false,
              data: [],
            },
            publicPhotos: {
              nextPage: false,
              prevPage: false,
              data: [],
            },
          };
      }

    case GET_USER_PHOTOS_FAILURE:
      return {
        ...state,
        photosIsLoading: false,
        error: action.error,
      };

    case POST_USER_PHOTOS_SUCCESS:
      return {
        ...state,
        photosIsUploading: false,
        [action.viewType + 'Photos']: {
          prevPage: state[action.viewType + 'Photos'].prevPage,
          nextPage: state[action.viewType + 'Photos'].nextPage,
          data: [...state[action.viewType + 'Photos'].data, action.photo],
        },
      };

    case DELETE_USER_PHOTOS_SUCCESS:
      return {
        ...state,
        photosIsDeleting: false,
        [action.viewType + 'Photos']: {
          prevPage: state[action.viewType + 'Photos'].prevPage,
          nextPage: state[action.viewType + 'Photos'].nextPage,
          data: state[action.viewType + 'Photos'].data.filter((photo: UserPhotosDTO) => photo.id !== action.id),
        },
      };

    //end photo
    //video

    case POST_USER_VIDEOS_REQUEST:
      return {
        ...state,
        videosIsUploading: true,
      };
    case DELETE_USER_VIDEOS_REQUEST:
      return {
        ...state,
        videosIsDeleting: true,
      };

    case POST_USER_VIDEOS_FAILURE:
      return {
        ...state,
        videosIsUploading: false,
        error: action.error,
      };
    case DELETE_USER_VIDEOS_FAILURE:
      return {
        ...state,
        videosIsDeleting: false,
        error: action.error,
      };

    case GET_USER_VIDEOS_REQUEST: {
      return {
        ...state,
        videosIsLoading: true,
        ...(action.offset ? {} : { privateVideos: { ...defaultUserVideosData } }),
        ...(action.offset ? {} : { publicVideos: { ...defaultUserVideosData } }),
      };
    }

    case GET_USER_VIDEOS_SUCCESS:
      switch (action.viewType) {
        case 'private':
          return {
            ...state,
            videosIsLoading: false,
            privateVideos: {
              nextPage: action.videos.nextPage,
              prevPage: action.videos.prevPage,
              data: [...state.privateVideos.data, ...action.videos.data],
            },
          };
        case 'public':
          return {
            ...state,
            videosIsLoading: false,
            publicVideos: {
              nextPage: action.videos.nextPage,
              prevPage: action.videos.prevPage,
              data: [...state.publicVideos.data, ...action.videos.data],
            },
          };
        default:
          return {
            ...state,
            videosIsLoading: false,
            privateVideos: {
              nextPage: true,
              prevPage: false,
              data: [],
            },
            publicVideos: {
              nextPage: false,
              prevPage: false,
              data: [],
            },
          };
      }

    case GET_USER_VIDEOS_FAILURE:
      return {
        ...state,
        videosIsLoading: false,
        error: action.error,
      };

    case SET_VIDEO_CONVERTED: {
      return {
        ...state,
        videosIsUploading: false,
        [action.viewType + 'Videos']: {
          prevPage: state[action.viewType + 'Videos'].prevPage,
          nextPage: state[action.viewType + 'Videos'].nextPage,
          data: [
            ...state[action.viewType + 'Videos'].data.filter((videoState) => videoState.id !== action.video.id),
            action.video,
          ],
        },
      };
    }
    case POST_USER_VIDEOS_SUCCESS:
      return {
        ...state,
        videosIsUploading: false,
        [action.viewType + 'Videos']: {
          prevPage: state[action.viewType + 'Videos'].prevPage,
          nextPage: state[action.viewType + 'Videos'].nextPage,
          data: [...state[action.viewType + 'Videos'].data, action.video],
        },
      };

    case DELETE_USER_VIDEOS_SUCCESS:
      return {
        ...state,
        videosIsDeleting: false,
        [action.viewType + 'Videos']: {
          prevPage: state[action.viewType + 'Videos'].prevPage,
          nextPage: state[action.viewType + 'Videos'].nextPage,
          data: state[action.viewType + 'Videos'].data.filter((video: UserVideosDTO) => video.id !== action.id),
        },
      };
    //end video
    case CLEAR_AVAILABLE_USERS_SUCCESS:
      return {
        ...state,
        usersWithActiveTextChat: [],
        activeVideo: {
          id: '',
          date: null,
        },
        userAvailableIsLoading: false,
        availableUsers: [],
      };

    case GET_AVAILABLE_USERS_REQUEST:
      return {
        ...state,
        userAvailableIsLoading: true,
        usersWithActiveTextChat: [],
        activeVideo: {
          id: '',
          date: null,
        },
      };

    case GET_AVAILABLE_USERS_SUCCESS: {
      const filteredUsers = action.users.filter(
        (user) => user.id !== state.currentUser?.profile?.id && user.gender !== state.currentUser.profile.gender
      );

      const updatedAvailableUsers = state.availableUsers.map((availableUser) => {
        const filteredUser = filteredUsers.find((user) => user.id === availableUser.id);
        if (filteredUser) {
          const updatedChat = (availableUser.chat || [])
            .filter((message) => {
              return isWithinLastDays(new Date(message.date), 1);
            })
            .map((message) => {
              return { ...message, isDisconnected: true };
            });
          return { ...filteredUser, chat: updatedChat, isOnline: true };
        }
        return availableUser;
      });

      const newAvailableUsers = filteredUsers.filter((filteredUser) => {
        return !state.availableUsers.some((availableUser) => availableUser.id === filteredUser.id);
      });

      const updatedAvailableUsersWithStatus = updatedAvailableUsers.map((user) => {
        if (!filteredUsers.some((filteredUser) => filteredUser.id === user.id)) {
          return { ...user, isOnline: false };
        }
        return user;
      });

      const sortedAvailableUsers = [...updatedAvailableUsersWithStatus, ...newAvailableUsers].sort((a, b) => {
        if (a.isOnline && !b.isOnline) {
          return -1;
        }
        if (!a.isOnline && b.isOnline) {
          return 1;
        }
        if (a.chat && a.chat.length > 0 && !(b.chat && b.chat.length > 0)) {
          return -1;
        }
        if (!(a.chat && a.chat.length > 0) && b.chat && b.chat.length > 0) {
          return 1;
        }
        return 0;
      });

      return {
        ...state,
        userAvailableIsLoading: false,
        availableUsers: sortedAvailableUsers,
        usersWithActiveTextChat: [],
      };
    }

    case GET_AVAILABLE_USERS_FAILURE:
      return {
        ...state,
        userAvailableIsLoading: false,
      };

    case GET_USERS_DIALOGS_REQUEST:
      if (action.config.offset > 0) {
        return {
          ...state,
          userDialogsIsLoading: false,
        };
      } else {
        return {
          ...state,
          userDialogsIsLoading: false,
          userDialogs: {
            nextPage: false,
            prevPage: false,
            data: [],
          },
        };
      }

    case GET_USERS_DIALOGS_SUCCESS: {
      return {
        ...state,
        userDialogsIsLoading: false,
        userDialogs: { ...action.dialogs, data: [...state.userDialogs.data, ...action.dialogs.data] },
      };
    }

    case GET_USERS_DIALOGS_FAILURE:
      return {
        ...state,
        userDialogsIsLoading: false,
      };

    case ADD_USERS_DIALOGS_SUCCESS: {
      const { dialogs: newDialog, changeCounter } = action.payload;
      const dialogIndex = state.userDialogs.data.findIndex(
        (message) => getDialogIndex(newDialog, message)
      );

      if (dialogIndex === -1) {
        const insertIndex = state.userDialogs.data.findIndex(findIsDialogPinned);
        if (insertIndex !== -1) {
          const updatedData = [...state.userDialogs.data];
          updatedData.splice(insertIndex + 1, 0, newDialog);

          return {
            ...state,
            userDialogs: {
              ...state.userDialogs,
              data: updatedData,
            },
          };
        } else {
          return {
            ...state,
            userDialogs: {
              ...state.userDialogs,
              data: [newDialog, ...state.userDialogs.data],
            },
          };
        }
      }

      const userDialogsData = [...state.userDialogs.data];
      const currentDialog = userDialogsData[dialogIndex];
      const currentDialogUnreadCount = changeCounter ? { unreadCount: currentDialog.unreadCount + 1 } : {};

      userDialogsData.splice(dialogIndex, 1);
      if (Number.isFinite(currentDialog.pinRanked)) {
        userDialogsData.splice(dialogIndex, 0, {
          ...newDialog,
          ...currentDialogUnreadCount,
          pinRanked: currentDialog.pinRanked
        });
      } else {
        userDialogsData.unshift({ ...newDialog, ...currentDialogUnreadCount });
      }
      userDialogsData.sort(dialogsSortByPinned);

      return {
        ...state,
        userDialogs: {
          ...state.userDialogs,
          data: userDialogsData,
        },
      };
    }

    case UPDATE_PIN_SUCCESS: {
      const { profileId, isPinned } = action.payload;

      const dialogsData = [...state.userDialogs.data];
      const index = dialogsData.findIndex(
        (dialog) => dialog.receiverProfile.id === profileId || dialog.senderProfile.id === profileId
      );

      if (index === -1) {
        return state;
      } else {
        const pinNumber = getLargestPinNumber(dialogsData) || 0
        dialogsData[index].pinRanked = isPinned ? pinNumber : undefined;
        dialogsData.sort(dialogsSortByPinned);

        return {
          ...state,
          userDialogs: {
            ...state.userDialogs,
            data: dialogsData,
          },
        };
      }
    }

    case READ_USERS_DIALOGS_REQUEST: {
      const { id } = action.payload;
      const existingIndex = state.userDialogs.data.findIndex(
        (message) => message.senderProfile.id === id || message.receiverProfile.id === id
      );

      if (existingIndex === -1) {
        return {
          ...state,
        };
      } else {
        const updatedData = state.userDialogs.data;

        updatedData[existingIndex] = {
          ...updatedData[existingIndex],
          seen: new Date().toISOString(),
          unreadCount: 0,
        };

        return {
          ...state,
          userDialogs: {
            ...state.userDialogs,
            data: [...updatedData],
          },
        };
      }
    }

    case TRANSLATE_MESSAGE_TO_CHAT_SUCCESS: {
      const { toProfileId, date, translate } = action.payload;

      const usersWithTranslatedChat: UserProfileState[] = [];

      for (const user of state.availableUsers) {
        const userChat = user.chat || [];

        if (toProfileId === user.id) {
          const index = userChat.findIndex((chatMessage) => {
            return chatMessage.date === date;
          });

          userChat[index] = {
            id: userChat[index]['id'],
            date: userChat[index]['date'],
            text: userChat[index]['text'],
            translate,
          };

          usersWithTranslatedChat.push({
            ...user,
            chat: [...userChat],
          });
        }
      }

      let filteredAvailableUser = [];

      if (usersWithTranslatedChat.length) {
        filteredAvailableUser = state.availableUsers.filter((user) => user.id !== usersWithTranslatedChat[0].id);
      }
      return {
        ...state,
        availableUsers: [...usersWithTranslatedChat, ...filteredAvailableUser],
        usersWithActiveTextChat: [],
      };
    }

    case REVEAL_PHOTO_CHAT_SUCCESS: {
      const { toProfileId, date, img } = action.payload;

      const usersWithImages: UserProfileState[] = [];

      for (const user of state.availableUsers) {
        const userChat = user.chat || [];

        if (toProfileId === user.id) {
          const index = userChat.findIndex((chatMessage) => {
            return chatMessage.date === date;
          });

          userChat[index] = {
            id: userChat[index]['id'],
            date: new Date(),
            text: userChat[index]['text'],
            img: {
              ...img,
              isPaid: true,
            },
            translate: '',
          };

          usersWithImages.push({
            ...user,
            chat: [...userChat],
          });
        }
      }

      let filteredAvailableUser = [];

      if (usersWithImages.length) {
        filteredAvailableUser = state.availableUsers.filter((user) => user.id !== usersWithImages[0].id);
      }
      return {
        ...state,
        availableUsers: [...usersWithImages, ...filteredAvailableUser],
      };
    }

    case REVEAL_VIDEO_CHAT_SUCCESS: {
      const { toProfileId, date, video } = action.payload;

      const usersWithVideos: UserProfileState[] = [];

      for (const user of state.availableUsers) {
        const userChat = user.chat || [];

        if (toProfileId === user.id) {
          const index = userChat.findIndex((chatMessage) => {
            return chatMessage.date === date;
          });

          userChat[index] = {
            id: userChat[index]['id'],
            date: new Date(),
            text: userChat[index]['text'],
            video: {
              ...video,
              isPaid: true,
            },
            translate: '',
          };

          usersWithVideos.push({
            ...user,
            chat: [...userChat],
          });
        }
      }

      let filteredAvailableUser = [];

      if (usersWithVideos.length) {
        filteredAvailableUser = state.availableUsers.filter((user) => user.id !== usersWithVideos[0].id);
      }
      return {
        ...state,
        availableUsers: [...usersWithVideos, ...filteredAvailableUser],
      };
    }

    case SET_UPDATE_AVAILABLE_USERS: {
      const { availableUsers = [] } = state;

      const isUserExist = availableUsers.some((user) => user.id === action.payload.id);

      let updatedAvailableUsers = availableUsers.map((user) => {
        if (user.id === action.payload.id) {
          return { ...user, ...action.payload, isOnline: true };
        }
        return user;
      });

      if (!isUserExist) {
        updatedAvailableUsers = [action.payload, ...updatedAvailableUsers];
      }

      const sortedAvailableUsers = updatedAvailableUsers.sort((a, b) => {
        if (a.isOnline && !b.isOnline) {
          return -1;
        }
        if (!a.isOnline && b.isOnline) {
          return 1;
        }
        if (a.chat && a.chat.length > 0 && !(b.chat && b.chat.length > 0)) {
          return -1;
        }
        if (!(a.chat && a.chat.length > 0) && b.chat && b.chat.length > 0) {
          return 1;
        }
        return 0;
      });

      if (isUserExist) {
        return {
          ...state,
          availableUsers: sortedAvailableUsers,
        };
      }

      return {
        ...state,
        availableUsers: [...sortedAvailableUsers],
      };
    }

    case SET_DISCONNECTED_AVAILABLE_USERS: {
      const { availableUsers = [], usersWithActiveTextChat = [] } = state;

      const updatedAvailableUsers = availableUsers.map((user) => {
        if (user.id === action.payload.id) {
          const updatedChat = user?.chat?.map((message) => {
            return { ...message, isDisconnected: true };
          });

          return { ...user, chat: updatedChat, isOnline: false };
        }
        return user;
      });

      const updatedUsersWithActiveTextChat = usersWithActiveTextChat.filter((id) => id !== action.payload.id);

      const sortedAvailableUsers = updatedAvailableUsers.sort((a, b) => {
        if (a.isOnline && !b.isOnline) {
          return -1;
        }
        if (!a.isOnline && b.isOnline) {
          return 1;
        }
        if (a.chat && a.chat.length > 0 && !(b.chat && b.chat.length > 0)) {
          return -1;
        }
        if (!(a.chat && a.chat.length > 0) && b.chat && b.chat.length > 0) {
          return 1;
        }
        return 0;
      });

      return {
        ...state,
        availableUsers: sortedAvailableUsers,
        usersWithActiveTextChat: updatedUsersWithActiveTextChat,
      };
    }

    case USER_BALANCE_CHARGED_SUCCESS: {
      const { profileId } = action.payload;
      const { usersWithActiveTextChat, videoActive } = state;

      if (videoActive && videoActive.videoProfileId === profileId) {
        return {
          ...state,
          videoActive: {
            ...state.videoActive,
            date: new Date(),
          },
          usersWithActiveTextChat: [],
        };
      }

      const updatedUsers = usersWithActiveTextChat.map((updatedValue) => {
        if (updatedValue.id === profileId) {
          return {
            id: updatedValue.id,
            date: new Date(),
          };
        } else {
          return updatedValue;
        }
      });

      return {
        ...state,
        usersWithActiveTextChat: updatedUsers,
      };
    }
    case DISCONNECT_CHAT: {
      const profileId = action.payload;
      const { usersWithActiveTextChat } = state;
      const usersWithNewChat = state.availableUsers.map((user) => {
        if (profileId === user.id) {
          const userChat = user.chat || [];
          const updatedChat = userChat.map((chatItem) => ({
            ...chatItem,
            isDisconnected: true,
          }));
          updatedChat.push({
            id: 'end',
            text: 'End text chat',
            translate: '',
            date: new Date(),
            isDisconnected: true,
          });
          return {
            ...user,
            chat: updatedChat,
          };
        }
        return user;
      });

      const filteredActiveChat = usersWithActiveTextChat.filter((filteredValue) => filteredValue.id !== profileId);

      return {
        ...state,
        availableUsers: [...usersWithNewChat],
        usersWithActiveTextChat: filteredActiveChat,
      };
    }

    case SET_VIDEO_CALL: {
      return {
        ...state,
        videoActive: {
          videoProfileId: action.payload.videoProfileId,
          date: new Date(new Date().getTime() - MINUS_CURRENT_TIMER_FOR_CHARGE_VALUE),
        },
        usersWithActiveTextChat: [],
      };
    }

    case SEND_PHOTO_MESSAGE_TO_CHAT_SUCCESS:
    case SEND_VIDEO_MESSAGE_TO_CHAT_SUCCESS:
    case SEND_MESSAGE_TO_CHAT_SUCCESS: {
      const { toProfileId, text, img, video } = action.payload;
      const { videoActive } = state;
      const usersWithNewChat: UserProfileState[] = [];

      let newUsersWithActiveTextChat = null;
      const { usersWithActiveTextChat = [] } = state;

      for (const user of state.availableUsers) {
        if (toProfileId === user.id) {
          const userChat = user.chat || [];

          let index = userChat.length;
          if (!videoActive || (videoActive && !videoActive?.videoProfileId)) {
            for (0 >= index; index--;) {
              const element = userChat[index];

              if (
                  element.id != state.currentUser?.profile?.id
                  && element.id != 'start'
              ) {
                if (element.isDisconnected && !isWithinLastMinutesParams(new Date(element.date), 3)) {
                  continue;
                }
                if (usersWithActiveTextChat.find((userProfileWithChat) => userProfileWithChat.id === element.id)) {
                  continue;
                } else {
                  newUsersWithActiveTextChat = {
                    id: element.id,
                    date: new Date(new Date().getTime() - MINUS_CURRENT_TIMER_FOR_CHARGE_VALUE),
                  };
                  break;
                }
              }
            }
          }

          const newMessageList = [];

          if (!userChat.length) {
            newMessageList.push({
              id: 'currentDate',
              date: new Date(),
              text: `${new Date().toDateString()}`,
              translate: '',
            });
          }

          if (text.includes("::sticker::url")) {
            newMessageList.push({
              id: state.currentUser?.profile?.id,
              date: new Date(),
              text: '',
              img: {
                small: text.replace('::sticker::url::', ''),
              },
            });
          } else {
            newMessageList.push({
              id: state.currentUser?.profile?.id,
              date: new Date(),
              text,
              img,
              video,
              translate: '',
            });
          }

          if (newUsersWithActiveTextChat) {
            newMessageList.push({
              id: 'start',
              date: new Date(),
              text: 'Start text chat',
              translate: '',
            });
          }

          usersWithNewChat.push({
            ...user,
            chat: [...userChat, ...newMessageList],
            itHasMyMessage: true,
          });
        }
      }

      let filteredAvailableUser = [];

      if (usersWithNewChat.length) {
        filteredAvailableUser = state.availableUsers.filter((user) => user.id !== usersWithNewChat[0].id);
      }

      if (newUsersWithActiveTextChat) {
        let filteredUserWithActiveTextChat = [];

        if (usersWithActiveTextChat.length) {
          filteredUserWithActiveTextChat = state.usersWithActiveTextChat.filter(
            (user) => user.id !== newUsersWithActiveTextChat.id
          );
        }

        return {
          ...state,
          availableUsers: [...usersWithNewChat, ...filteredAvailableUser],
          usersWithActiveTextChat: [...filteredUserWithActiveTextChat, newUsersWithActiveTextChat],
        };
      }
      return {
        ...state,
        availableUsers: [...usersWithNewChat, ...filteredAvailableUser],
      };
    }

    case RECEIVE_PHOTO_MESSAGE_TO_CHAT_SUCCESS:
    case RECEIVE_VIDEO_MESSAGE_TO_CHAT_SUCCESS:
    case RECEIVE_MESSAGE_TO_CHAT_SUCCESS: {
      const { profileId, text = '', currentUserId, img, video, isChatOpen } = action.payload;

      const currentTime = new Date().getTime();

      let newUsersWithActiveTextChat = null;
      const { usersWithActiveTextChat = [], videoActive } = state;

      let messageToInsert = null;
      if (text.includes("::sticker::url")) {
        messageToInsert = {
          id: profileId,
          date: new Date(),
          text: '',
          img: {
            small: text.replace('::sticker::url::', ''),
          },
        };
      } else {
        messageToInsert = {
          id: profileId,
          date: new Date(),
          text,
          img,
          video,
          translate: '',
        };
      }

      let lastMessageWasAlongTimeAgo = false;

      const usersWithNewChat: UserProfileState[] = [];
      for (const user of state.availableUsers) {
        if (profileId === user.id) {
          const userChat = user.chat || [];

          let index = userChat.length;
          if (!videoActive || (videoActive && !videoActive?.videoProfileId)) {
            for (0 >= index; index--;) {
              const element = userChat[index];
              if (
                  element.id != state.currentUser?.profile?.id
                  && element.id != 'start'
              ) {
                if (element.isDisconnected && !isWithinLastMinutesParams(new Date(element.date), 3)) {
                  break;
                }
                if (usersWithActiveTextChat.find((userProfileWithChat) => userProfileWithChat.id === profileId)) {
                  break;
                }
                newUsersWithActiveTextChat = {
                  id: profileId,
                  date: new Date(currentTime - MINUS_CURRENT_TIMER_FOR_CHARGE_VALUE),
                };
              }
            }
          }
          const newMessageList = [];

          if (!userChat.length) {
            newMessageList.push({
              id: 'currentDate',
              date: new Date(),
              text: `${new Date().toDateString()}`,
              translate: '',
            });
          }

          newMessageList.push(messageToInsert);

          if (newUsersWithActiveTextChat) {
            newMessageList.push({
              id: 'start',
              date: new Date(),
              text: 'Start text chat',
              translate: '',
            });
          }

          if (usersWithNewChat && userChat.length) {
            const lastMessage = userChat[userChat.length - 1];
            lastMessageWasAlongTimeAgo = new Date(lastMessage.date).getTime() < currentTime - 60000 ? true : false;
          }

          usersWithNewChat.push({
            ...user,
            chat: [...userChat, ...newMessageList],
            itHasReferenceMessage: true,
            itHasNewMessage: true,
          });
        }
      }

      let filteredAvailableUser = [];

      if (usersWithNewChat.length) {
        filteredAvailableUser = state.availableUsers.filter((user) => user.id !== usersWithNewChat[0].id);
      }

      let count = 0;

      for (const message of usersWithNewChat[0].chat) {
        if (!message.isDisconnected && isWithinLastMinutesParams(new Date(message.date), 3)) {
          count++;
        }
      }

      if (
        !isChatOpen &&
        (count === 1 ||
          (usersWithNewChat &&
            usersWithNewChat[0] &&
            usersWithNewChat[0].chat &&
            usersWithNewChat[0].chat.length === 2) ||
          lastMessageWasAlongTimeAgo)
      ) {
        let invites = state.invites || [];

        return {
          ...state,
          invites: [
            ...invites,
            {
              ...usersWithNewChat[0],
              chat: [messageToInsert],
            },
          ],
          availableUsers: [...usersWithNewChat, ...filteredAvailableUser],
        };
      }
      if (newUsersWithActiveTextChat) {
        //
        let filteredUserWithActiveTextChat = [];

        if (usersWithActiveTextChat.length) {
          filteredUserWithActiveTextChat = state.usersWithActiveTextChat.filter(
            (user) => user.id !== newUsersWithActiveTextChat.id
          );
        }
        const hasMyMessage = usersWithNewChat.find((userWithChat) => userWithChat.id === newUsersWithActiveTextChat.id);
        const itHasMyMessage = hasMyMessage.chat.find((chatDialogMessages) => chatDialogMessages.id === currentUserId);
        if (itHasMyMessage) {
          return {
            ...state,
            availableUsers: [...usersWithNewChat, ...filteredAvailableUser],
            usersWithActiveTextChat: [...filteredUserWithActiveTextChat, newUsersWithActiveTextChat],
          };
        }
      }

      return {
        ...state,
        availableUsers: [...usersWithNewChat, ...filteredAvailableUser],
      };
    }

    case READ_MESSAGE: {
      const { uid } = action.payload;
      const { availableUsers } = state;

      const updatedUsers = availableUsers.map((user) => {
        if (user.id === uid) {
          return {
            ...user,
            itHasNewMessage: false,
          };
        }
        return user;
      });

      return {
        ...state,
        availableUsers: updatedUsers,
      };
    }

    case REMOVE_INVITE: {
      const profileId = action.payload;
      const filteredInvites = state.invites.filter((invite) => invite.id !== profileId);

      return {
        ...state,
        invites: filteredInvites,
      };
    }

    case REMOVE_VIDEO_INVITE: {
      const profileId = action.payload;
      const filteredInvites = state.videoInvites.filter((invite) => invite.profileId !== profileId);

      return {
        ...state,
        videoInvites: filteredInvites,
      };
    }

    case ADD_VIDEO_INVITE: {
      const data = action.payload;
      const filteredInvites = state.videoInvites.filter((invite) => invite.profileId !== data.profileId);

      return {
        ...state,
        videoInvites: [...filteredInvites, data],
      };
    }

    case GET_PROFILE_PHOTOS_REQUEST: {
      return {
        ...state,
        photosIsLoading: true,
      };
    }

    case GET_PROFILE_PHOTOS_SUCCESS: {
      const publicPhotos = action.photos.data;

      const availableUsers = state.availableUsers.map((user) => {
        if (action.profileId === user.id) {
          const publicPhoto: UserPhotosDTO[] = publicPhotos ? publicPhotos : user.publicPhotos?.data || [];

          const changedUser = {
            ...user,
            ...(!publicPhoto.length
              ? {}
              : {
                publicPhotos: {
                  prevPage: action.photos.prevPage,
                  nextPage: action.photos.nextPage,
                  data: publicPhoto,
                },
              }),
          };
          console.log('changedUser', changedUser);
          return changedUser;
        } else {
          return user;
        }
      });
      return {
        ...state,
        availableUsers: availableUsers,
      };
    }
    case GET_PROFILE_PHOTOS_FAILURE: {
      return {
        ...state,
        photosIsLoading: false,
        error: action.error,
      };
    }

    case GET_PROFILE_VIDEOS_REQUEST: {
      return {
        ...state,
        videosIsLoading: true,
      };
    }

    case GET_PROFILE_VIDEOS_SUCCESS: {
      const publicVideos = action.videos.data;

      const availableUsers = state.availableUsers.map((user) => {
        if (action.profileId === user.id) {
          const publicVideo: UserVideosDTO[] = publicVideos ? publicVideos : user.publicVideos?.data || [];

          const changedUser = {
            ...user,
            ...(!publicVideo.length
              ? {}
              : {
                publicVideos: {
                  prevPage: action.videos.prevPage,
                  nextPage: action.videos.nextPage,
                  data: publicVideo,
                },
              }),
          };
          console.log('changedUser', changedUser);
          return changedUser;
        } else {
          return user;
        }
      });
      return {
        ...state,
        availableUsers: availableUsers,
      };
    }
    case GET_PROFILE_VIDEOS_FAILURE: {
      return {
        ...state,
        videosIsLoading: false,
        error: action.error,
      };
    }

    case SET_BOOKMARKS_REQUEST: {
      return {
        ...state,
        bookmarksLoading: true,
      };
    }
    case SET_BOOKMARKS_SUCCESS: {
      return {
        ...state,
        bookmarksLoading: false,
        bookmarks: [...state.bookmarks, action.user],
      };
    }
    case SET_BOOKMARKS_FAILURE: {
      return {
        ...state,
        bookmarksLoading: false,
      };
    }
    case GET_BOOKMARKS_REQUEST: {
      return {
        ...state,
        bookmarksLoading: true,
      };
    }
    case GET_BOOKMARKS_SUCCESS: {
      return {
        ...state,
        bookmarksLoading: false,
        bookmarks: action.users,
      };
    }
    case GET_BOOKMARKS_FAILURE: {
      return {
        ...state,
        bookmarksLoading: false,
      };
    }
    case DELETE_BOOKMARKS_REQUEST: {
      return {
        ...state,
        bookmarksLoading: true,
      };
    }
    case DELETE_BOOKMARKS_SUCCESS: {
      const bookmarks = state.bookmarks.filter((bookmark) => bookmark.id !== action.profileId);
      return {
        ...state,
        bookmarksLoading: false,
        bookmarks,
      };
    }

    case DELETE_BOOKMARKS_FAILURE: {
      return {
        ...state,
        bookmarksLoading: false,
      };
    }

    case SET_BLOCKED_BY_ME_REQUEST: {
      return {
        ...state,
        blockedUsersLoading: true,
      };
    }
    case SET_BLOCKED_BY_ME_SUCCESS: {
      return {
        ...state,
        blockedUsersLoading: false,
        blockedUsers: [...state.blockedUsers, action.payload.user],
      };
    }
    case SET_BLOCKED_BY_ME_FAILURE: {
      return {
        ...state,
        blockedUsersLoading: false,
      };
    }

    case DELETE_BLOCKED_BY_ME_REQUEST: {
      return {
        ...state,
        blockedUsersLoading: true,
      };
    }
    case DELETE_BLOCKED_BY_ME_SUCCESS: {
      const blockedUsers = state.blockedUsers.filter((blocked) => blocked.blockedProfileId !== action.profileId);
      return {
        ...state,
        blockedUsersLoading: false,
        blockedUsers,
      };
    }
    case DELETE_BLOCKED_BY_ME_FAILURE: {
      return {
        ...state,
        blockedUsersLoading: false,
      };
    }

    case GET_BLOCKED_USERS_REQUEST: {
      return {
        ...state,
        blockedUsersLoading: true,
      };
    }
    case GET_BLOCKED_USERS_SUCCESS: {
      return {
        ...state,
        blockedUsersLoading: false,
        blockedUsers: action.users,
      };
    }
    case GET_BLOCKED_USERS_FAILURE: {
      return {
        ...state,
        blockedUsersLoading: false,
      };
    }

    case UPDATE_BALANCE_REQUEST: {
      return {
        ...state,
        wallet: {
          ...state.wallet,
          isLoading: true,
        },
      };
    }

    case UPDATE_BALANCE_SUCCESS: {
      const { balance } = action.payload;
      return {
        ...state,
        wallet: {
          ...state.wallet,
          isLoading: false,
          balance,
        },
      };
    }

    case UPDATE_BALANCE_FAILED: {
      return {
        ...state,
        wallet: {
          ...state.wallet,
          isLoading: false,
        },
      };
    }

    case UPDATE_INAPP_REQUEST: {
      return {
        ...state,
        inappMessage: {
          ...state.inAppMessages,
          isLoading: true,
        },
      };
    }

    case UPDATE_INAPP_SUCCESS: {
      const { inAppMessages } = action.payload;
      return {
        ...state,
        inAppMessages: {
          ...state.inAppMessages,
          isLoading: false,
          ...inAppMessages,
        },
      };
    }

    case UPDATE_INAPP_FAILED: {
      return {
        ...state,
        inAppMessages: {
          ...state.inAppMessages,
          isLoading: false,
        },
      };
    }

    case FILTER_INAPP_REQUEST: {
      return {
        ...state,
        inappMessage: {
          ...state.inAppMessages,
          isLoading: true,
        },
      };
    }

    case FILTER_INAPP_SUCCESS: {
      const { inappDistributionId } = action.payload;
      return {
        ...state,
        inAppMessages: {
          ...state.inAppMessages,
          isLoading: false,
          data: state.inAppMessages.data.filter((element) => {
            return element.id !== inappDistributionId;
          }),
        },
      };
    }

    case FILTER_INAPP_FAILED: {
      return {
        ...state,
        inAppMessages: {
          ...state.inAppMessages,
          isLoading: false,
        },
      };
    }

    case UPDATE_QUESTIONS_REQUEST: {
      return {
        ...state,
      };
    }

    case UPDATE_QUESTIONS_SUCCESS: {
      const { questions } = action.payload;
      return {
        ...state,
        questions,
      };
    }

    case UPDATE_QUESTIONS_FAILED: {
      return {
        ...state,
        questions: [...state.questions],
      };
    }

    case FILTER_QUESTIONS_REQUEST: {
      return {
        ...state,
        questions: [...state.questions],
      };
    }

    case FILTER_QUESTIONS_SUCCESS: {
      const { id } = action.payload;
      return {
        ...state,
        questions: [
          ...state.questions.filter((element) => {
            return element.id !== id;
          }),
        ],
      };
    }

    case FILTER_QUESTIONS_FAILED: {
      return {
        ...state,
        questions: [...state.questions],
      };
    }

    case CLOSE_QUESTIONS_FOR_DAYS_SUCCESS: {
      return {
        ...state,
        questionsForget: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 3),
      };
    }

    case CHARGE_BALANCE_REQUEST: {
      return {
        ...state,
        wallet: {
          ...state.wallet,
          isLoading: true,
        },
      };
    }

    case CHARGE_BALANCE_SUCCESS: {
      const { balance } = action.payload;
      return {
        ...state,
        wallet: {
          ...state.wallet,
          isLoading: false,
          balance,
        },
      };
    }

    case CHARGE_BALANCE_FAILED: {
      return {
        ...state,
        wallet: {
          ...state.wallet,
          isLoading: false,
        },
      };
    }

    case GET_SETTINGS_REQUEST: {
      return {
        ...state,
        settings: {
          ...state.settings,
          isLoading: true,
        },
      };
    }

    case GET_SETTINGS_SUCCESS: {
      const { settings } = action.payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          isLoading: false,
          ...settings,
        },
      };
    }

    case GET_SETTINGS_FAILED: {
      return {
        ...state,
        settings: {
          ...state.settings,
          isLoading: false,
        },
      };
    }

    case GET_EMAIL_EVENTS_BLACKLIST_REQUEST: {
      return {
        ...state,
      };
    }

    case GET_EMAIL_EVENTS_BLACKLIST_SUCCESS: {
      const { emailEventsBlacklist } = action.payload;
      return {
        ...state,
        emailEventsBlacklist: [...emailEventsBlacklist],
      };
    }

    case GET_EMAIL_EVENTS_BLACKLIST_FAILED: {
      return {
        ...state,
      };
    }

    case GET_ACQUIRING_REQUEST: {
      return {
        ...state,
        acquiring: [...state.acquiring],
      };
    }

    case GET_ACQUIRING_SUCCESS: {
      const { acquiring } = action.payload;
      return {
        ...state,
        acquiring: [...acquiring.map((e: { gateway: string }) => e.gateway)],
      };
    }

    case GET_ACQUIRING_FAILED: {
      return {
        ...state,
        acquiring: [...state.acquiring],
      };
    }

    case SET_USER_HEADER_SEARCH: {
      return {
        ...state,
        headerUserSearch: action.payload,
      };
    }

    case GET_DIALOG_COUNTERS_SUCCESS: {
      return {
        ...state,
        dialogCounters: action.payload,
      };
    }

    case SOCKET_UPDATE_DIALOG_COUNT: {
      return {
        ...state,
        dialogCounters: action.payload,
      };
    }

    default:
      return state;
  }
};

export default users;
