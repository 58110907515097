import React, { useRef } from 'react';
import {checkIsSingleEmoji} from "@/helpers/helpers";
import StickersContainerRecommendations
    from "@/components/base/StickersContainer/components/StickersContainerRecommendations";
import {StickersContainerProps} from "@/components/base/StickersContainer/StickerContainer.props";
import StickerContainerPacks from "@/components/base/StickersContainer/components/StickersContainerPacks";
import {useClickOutside} from "@/hooks/useClickOutside";

const StickersContainer = ({
   inputText,
   isOpen,
   onClick,
   onClose
}: StickersContainerProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const showRecommendations = !isOpen && checkIsSingleEmoji(inputText);

    useClickOutside(ref, () => onClose());

    return <div ref={ref}>
        {isOpen && <StickerContainerPacks onClick={onClick} />}
        {showRecommendations && <StickersContainerRecommendations onClick={onClick} text={inputText}/>}
    </div>
};

export default StickersContainer;
