import { makeStyles } from '@mui/styles';
import React, { useMemo } from 'react';
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  button: {
    cursor: 'pointer',
    width: '74px',
    height: '74px',
  },
  image: {
    width: '100% !important',
    height: '100% !important',
    objectFit: 'contain'
  },
  viewOnly: {
    cursor: 'default',
    width: '100px',
    height: '100px',

    '& $image': {
      cursor: 'default !important',
      objectFit: 'contain'
    }
  },
  small: {
    cursor: 'pointer',
  },
  extraSmall: {
    width: '24px',
    height: '24px',
  }
}));

export enum StickersUploadButtonType {
  base = 'base',
  viewOnly = 'viewOnly',
  small = 'small',
  extraSmall = 'extraSmall',
}

type StickersUploadButtonProps = {
  item: {
    image: string;
    unicode: string;
    id: string;
  };
  onClick?: (id: string, item?: { image: string; unicode: string; id: string }) => void;
  type: StickersUploadButtonType;
};

const StickersItemButton = ({
  item,
  onClick,
  type,
}: StickersUploadButtonProps) => {
  const classes = useStyles();

  const containerClassName = useMemo(() => {
    if (type === StickersUploadButtonType.viewOnly) {
      return clsx(classes.button, classes.viewOnly);
    }
    if (type === StickersUploadButtonType.small) {
      return clsx(classes.button, classes.viewOnly);
    }

    if (type === StickersUploadButtonType.extraSmall) {
      return clsx(classes.button, classes.extraSmall);
    }
    return classes.button;
  }, [classes.button, classes.viewOnly, type])

  const onClickItem = () => {
    if (type === StickersUploadButtonType.viewOnly) {
      return null
    }
    onClick(item.id, item)
  }

  return <div className={containerClassName} onClick={onClickItem}>
    <img src={item.image} alt={item.unicode} className={classes.image} />
  </div>
};

export default StickersItemButton;
